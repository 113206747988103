import { TranslationsService } from './helpers/translations.service';
import { AuthService } from 'app/core/auth/auth.service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { forkJoin, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class InitialDataResolver implements Resolve<any>
{
    Menu = {
        "ar" : [
            {
                "id"      : "dashboard",
                "title"   : "الاحصائيات",
                "type"    : "basic",
                "icon"    : "home",
                "link"    : "/dashboard"
            },
            {
                "id"      : "orders",
                "title"   : "الطلبات",
                "type"    : "basic",
                "icon"    : "room_service",
                "link"    : "/orders"
            },
            {
                "id"      : "finance",
                "title"   : "المالية",
                "type"    : "basic",
                "icon"    : "attach_money",
                "link"    : "/finance"
            },
            {
                "id"      : "covering-areas",
                "title"   : "مناطق التغطية",
                "type"    : "basic",
                "icon"    : "location_on",
                "link"    : "/covering-areas"
            },
            {
                "id"      : "menu",
                "title"   : "الوجبات",
                "type"    : "basic",
                "icon"    : "restaurant",
                "link"    : "/menu"
            },
            {
                "id"      : "categories",
                "title"   : "التصنيفات",
                "type"    : "basic",
                "icon"    : "category",
                "link"    : "/categories"
            },
            {
                "id"      : "drivers",
                "title"   : "موظفي التوصيل",
                "type"    : "basic",
                "icon"    : "people",
                "link"    : "/drivers"
            },
            {
                "id"      : "campaigns",
                "title"   : "الحملات",
                "type"    : "basic",
                "icon"    : "campaign",
                "link"    : "/campaigns"
            },
            {
                "id"      : "joaan-delivery",
                "title"   : "توصيل من جوعان",
                "type"    : "basic",
                "icon"    : "people",
                "link"    : "/joaan-delivery"
            },
            {
                "id"      : "Comments-and-ratings",
                "title"   : "التعليقات",
                "type"    : "basic",
                "icon"    : "comment",
                "link"    : "/Comments-and-ratings"
            },
        ],
        "en" : [
            {
                "id"      : "dashboard",
                "title"   : "Dashboard",
                "type"    : "basic",
                "icon"    : "home",
                "link"    : "/dashboard"
            },
            {
                "id"      : "orders",
                "title"   : "Orders",
                "type"    : "basic",
                "icon"    : "room_service",
                "link"    : "/orders"
            },
            {
                "id"      : "finance",
                "title"   : "finance",
                "type"    : "basic",
                "icon"    : "attach_money",
                "link"    : "/finance"
            },
            {
                "id"      : "covering-areas",
                "title"   : "Covering Areas",
                "type"    : "basic",
                "icon"    : "location_on",
                "link"    : "/covering-areas"
            },
            {
                "id"      : "menu",
                "title"   : "Menu",
                "type"    : "basic",
                "icon"    : "restaurant",
                "link"    : "/menu"
            },
            {
                "id"      : "categories",
                "title"   : "categories",
                "type"    : "basic",
                "icon"    : "category",
                "link"    : "/categories"
            },
            {
                "id"      : "drivers",
                "title"   : "Drivers",
                "type"    : "basic",
                "icon"    : "people",
                "link"    : "/drivers"
            },
            {
                "id"      : "campaigns",
                "title"   : "campaigns",
                "type"    : "basic",
                "icon"    : "campaign",
                "link"    : "/campaigns"
            },
            {
                "id"      : "joaan-delivery",
                "title"   : "Joaan Delivery",
                "type"    : "basic",
                "icon"    : "people",
                "link"    : "/joaan-delivery"
            },
            {
                "id"      : "Comments-and-ratings",
                "title"   : "Comments",
                "type"    : "basic",
                "icon"    : "comment",
                "link"    : "/Comments-and-ratings"
            },
        ],
        "tr" : [
            {
                "id"      : "dashboard",
                "title"   : "Ayrıntılı Raporlar",
                "type"    : "basic",
                "icon"    : "home",
                "link"    : "/dashboard"
            },
            {
                "id"      : "orders",
                "title"   : "Siparişler",
                "type"    : "basic",
                "icon"    : "room_service",
                "link"    : "/orders"
            },
            {
                "id"      : "finance",
                "title"   : "finans",
                "type"    : "basic",
                "icon"    : "attach_money",
                "link"    : "/finance"
            },
            {
                "id"      : "covering-areas",
                "title"   : "Hizmet Alanları",
                "type"    : "basic",
                "icon"    : "location_on",
                "link"    : "/covering-areas"
            },
            {
                "id"      : "menu",
                "title"   : "Menü",
                "type"    : "basic",
                "icon"    : "restaurant",
                "link"    : "/menu"
            },
            {
                "id"      : "categories",
                "title"   : "Kategoriler",
                "type"    : "basic",
                "icon"    : "category",
                "link"    : "/categories"
            },
            {
                "id"      : "drivers",
                "title"   : "Kurye Personelleri",
                "type"    : "basic",
                "icon"    : "people",
                "link"    : "/drivers"
            },
            {
                "id"      : "campaigns",
                "title"   : "kampanyalar",
                "type"    : "basic",
                "icon"    : "campaign",
                "link"    : "/campaigns"
            },
            {
                "id"      : "joaan-delivery",
                "title"   : "Joaan Teslimatı İste",
                "type"    : "basic",
                "icon"    : "people",
                "link"    : "/joaan-delivery"
            },
            {
                "id"      : "Comments-and-ratings",
                "title"   : "Yorumlar ve derecelendirmeler",
                "type"    : "basic",
                "icon"    : "comment",
                "link"    : "/Comments-and-ratings"
            },
        ]
    };
    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     */
    constructor(
        private _httpClient: HttpClient,
        private _AuthService: AuthService,
        private __ : TranslationsService
    )
    {
        this.Menu = {
            "ar" : [
                {
                    "id"      : "dashboard",
                    "title"   : "الاحصائيات",
                    "type"    : "basic",
                    "icon"    : "home",
                    "link"    : "/dashboard"
                },
                {
                    "id"      : "orders",
                    "title"   : "الطلبات",
                    "type"    : "basic",
                    "icon"    : "room_service",
                    "link"    : "/orders"
                },
                {
                    "id"      : "finance",
                    "title"   : "المالية",
                    "type"    : "basic",
                    "icon"    : "attach_money",
                    "link"    : "/finance"
                },
                {
                    "id"      : "covering-areas",
                    "title"   : "مناطق التغطية",
                    "type"    : "basic",
                    "icon"    : "location_on",
                    "link"    : "/covering-areas"
                },
                {
                    "id"      : "menu",
                    "title"   : "الوجبات",
                    "type"    : "basic",
                    "icon"    : "restaurant",
                    "link"    : "/menu"
                },
                {
                    "id"      : "categories",
                    "title"   : "التصنيفات",
                    "type"    : "basic",
                    "icon"    : "category",
                    "link"    : "/categories"
                },
                {
                    "id"      : "drivers",
                    "title"   : "موظفي التوصيل",
                    "type"    : "basic",
                    "icon"    : "people",
                    "link"    : "/drivers"
                },
                // {
                //     "id"      : "packages",
                //     "title"   : "المواد الأولية",
                //     "type"    : "basic",
                //     "icon"    : "view_compact",
                //     "link"    : 'redirect',
                // },
                {
                    "id"      : "campaigns",
                    "title"   : "الحملات",
                    "type"    : "basic",
                    "icon"    : "campaign",
                    "link"    : "/campaigns"
                },
                {
                    "id"      : "joaan-delivery",
                    "title"   : "توصيل من جوعان",
                    "type"    : "basic",
                    "icon"    : "people",
                    "link"    : "/joaan-delivery"
                },
                {
                    "id"      : "Comments-and-ratings",
                    "title"   : "التعليقات",
                    "type"    : "basic",
                    "icon"    : "comment",
                    "link"    : "/Comments-and-ratings"
                },
            ],
            "en" : [
                {
                    "id"      : "dashboard",
                    "title"   : "Dashboard",
                    "type"    : "basic",
                    "icon"    : "home",
                    "link"    : "/dashboard"
                },
                {
                    "id"      : "orders",
                    "title"   : "Orders",
                    "type"    : "basic",
                    "icon"    : "room_service",
                    "link"    : "/orders"
                },
                {
                    "id"      : "finance",
                    "title"   : "finance",
                    "type"    : "basic",
                    "icon"    : "attach_money",
                    "link"    : "/finance"
                },
                {
                    "id"      : "covering-areas",
                    "title"   : "Covering Areas",
                    "type"    : "basic",
                    "icon"    : "location_on",
                    "link"    : "/covering-areas"
                },
                {
                    "id"      : "menu",
                    "title"   : "Menu",
                    "type"    : "basic",
                    "icon"    : "restaurant",
                    "link"    : "/menu"
                },
                {
                    "id"      : "categories",
                    "title"   : "categories",
                    "type"    : "basic",
                    "icon"    : "category",
                    "link"    : "/categories"
                },
                {
                    "id"      : "drivers",
                    "title"   : "Drivers",
                    "type"    : "basic",
                    "icon"    : "people",
                    "link"    : "/drivers"
                },
                // {
                //     "id"      : "packages",
                //     "title"   : "raw materials",
                //     "type"    : "basic",
                //     "icon"    : "view_compact",
                //     "link"    : 'redirect',
                // },
                {
                    "id"      : "campaigns",
                    "title"   : "campaigns",
                    "type"    : "basic",
                    "icon"    : "campaign",
                    "link"    : "/campaigns"
                },
                {
                    "id"      : "joaan-delivery",
                    "title"   : "Joaan Delivery",
                    "type"    : "basic",
                    "icon"    : "people",
                    "link"    : "/joaan-delivery"
                },
                {
                    "id"      : "Comments-and-ratings",
                    "title"   : "Comments",
                    "type"    : "basic",
                    "icon"    : "comment",
                    "link"    : "/Comments-and-ratings"
                },
            ],
            "tr" : [
                {
                    "id"      : "dashboard",
                    "title"   : "Ayrıntılı Raporlar",
                    "type"    : "basic",
                    "icon"    : "home",
                    "link"    : "/dashboard"
                },
                {
                    "id"      : "orders",
                    "title"   : "Siparişler",
                    "type"    : "basic",
                    "icon"    : "room_service",
                    "link"    : "/orders"
                },
                {
                    "id"      : "finance",
                    "title"   : "finans",
                    "type"    : "basic",
                    "icon"    : "attach_money",
                    "link"    : "/finance"
                },
                {
                    "id"      : "covering-areas",
                    "title"   : "Hizmet Alanları",
                    "type"    : "basic",
                    "icon"    : "location_on",
                    "link"    : "/covering-areas"
                },
                {
                    "id"      : "menu",
                    "title"   : "Menü",
                    "type"    : "basic",
                    "icon"    : "restaurant",
                    "link"    : "/menu"
                },
                {
                    "id"      : "categories",
                    "title"   : "kategoriler",
                    "type"    : "basic",
                    "icon"    : "category",
                    "link"    : "/categories"
                },
                {
                    "id"      : "drivers",
                    "title"   : "Kurye Personelleri",
                    "type"    : "basic",
                    "icon"    : "people",
                    "link"    : "/drivers"
                },
                // {
                //     "id"      : "packages",
                //     "title"   : "İşlenmemiş içerikler",
                //     "type"    : "basic",
                //     "icon"    : "view_compact",
                //     "link"    : 'redirect',
                // },
                {
                    "id"      : "campaigns",
                    "title"   : "kampanyalar",
                    "type"    : "basic",
                    "icon"    : "campaign",
                    "link"    : "/campaigns"
                },
                {
                    "id"      : "joaan-delivery",
                    "title"   : "Joaan Teslimatı İste",
                    "type"    : "basic",
                    "icon"    : "people",
                    "link"    : "/joaan-delivery"
                },
                {
                    "id"      : "Comments-and-ratings",
                    "title"   : "Yorumlar ve derecelendirmeler",
                    "type"    : "basic",
                    "icon"    : "comment",
                    "link"    : "/Comments-and-ratings"
                },
            ]
        };
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Load messages
     *
     * @private
     */
    private _loadMessages(): Observable<any>
    {
        return this._httpClient.get('api/common/messages');
    }

    /**
     * Load navigation data
     *
     * @private
     */
    private _loadNavigation(): Observable<any>
    {
        // return this._httpClient.get('/ar-menu.json');
        return this._httpClient.get('api/common/navigation');
    }

    /**
     * Load notifications
     *
     * @private
     */
    private _loadNotifications(): Observable<any>
    {
        return this._httpClient.get('api/common/notifications');
    }

    /**
     * Load shortcuts
     *
     * @private
     */
    private _loadShortcuts(): Observable<any>
    {
        return this._httpClient.get('api/common/shortcuts');
    }

    /**
     * Load user
     *
     * @private
     */
    private _loadUser(): Observable<any>
    {
        return of(this._AuthService.User);
    }

    private _loadBranch(): Observable<any>
    {
        return of(this._AuthService.Branch);
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Resolver
     *
     * @param route
     * @param state
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any>
    {
        return forkJoin([

            // Messages
            this._loadMessages(),

            // Navigation data
            this._loadNavigation(),

            // Notifications
            this._loadNotifications(),

            // Shortcuts
            this._loadShortcuts(),

            // User
            this._loadUser(),

            // Branch
            this._loadBranch()
        ])
        .pipe(
            map((data) => {
                return {
                    messages     : data[0].messages,
                    navigation   : {
                        compact   : data[1].compact,
                        default   : this.Menu[ this.__.language.locale ],
                        futuristic: data[1].futuristic,
                        horizontal: this.Menu[ this.__.language.locale ]
                    },
                    notifications: data[2].notifications,
                    shortcuts    : data[3].shortcuts,
                    user         : data[4],
                    branch       : data[5]
                };
            })
        );
    }
}
