import { Component, OnInit } from '@angular/core';
import { AuthService } from 'app/core/auth/auth.service';

@Component({
  selector: 'app-redirect-to-link',
  templateUrl: './redirect-to-link.component.html',
  styleUrls: ['./redirect-to-link.component.scss']
})
export class RedirectToLinkComponent implements OnInit {

  constructor(
    private _AuthService: AuthService
  ) { }

  ngOnInit(): void {
    window.open('https://tr.hungry-app.com/api/wholesale/index?token=' + this._AuthService.accessToken);
  }

}
