<div class="header">
    <div class="title d-block" style="width: 100% !important;">{{ 'Restaurant Status' | translate }}</div>

    <!-- <button class="close-button"
            mat-icon-button
            (click)="closeModal()"
            [tabIndex]="-1">
        <mat-icon [svgIcon]="'close'"></mat-icon>
    </button> -->
</div>

<div class="modal-content">
    <span *ngIf="viewMyToDate" style="color:red;font-size:12px">{{ 'Restaurant Closed To' | translate }} : {{mydate_to_date}}</span>

    <mat-vertical-stepper #stepper>
        <mat-step>
          <ng-template matStepLabel>{{ 'option one' | translate }}</ng-template>
            <mat-slide-toggle (change)="switchStatus()" [checked]="currentStatus == '1'" [color]="'primary'" class="mat-option-one">
                <strong *ngIf="currentStatus == '1'" class="text-success">{{ 'Available' | translate }}</strong>
                <strong *ngIf="currentStatus == '0'" class="text-danger">{{ 'Closed' | translate }}</strong>
            </mat-slide-toggle>
        </mat-step>
        <mat-step class="mat-step-option-tow">
            <ng-template matStepLabel>{{ 'option tow' | translate }}</ng-template>
            <button class="mt-1 mat-step-option-tow-button"
                        (click)="CloseToEndDay()">
                        <mat-icon [svgIcon]="'update'"></mat-icon>
                      <span class="mt-1">  {{ 'Timing' | translate }}</span>
            </button>
            <button class="set-null btn btn-danger" (click)="SetNull_CloseToEndDay()">
                <mat-icon [svgIcon]="'delete_forever'"></mat-icon>
            </button>
          </mat-step>
          <mat-step>
            <ng-template matStepLabel>{{ 'option three' | translate }}</ng-template>
                <form [formGroup]="timingForm">
                    <!-- <mat-form-field class="form-date-p mt-1" appearance="fill">
                        <input matInput [matDatepicker]="from_date" [formControlName]="'from_date'"
                        placeholder="{{mydate_from_date}}"
                        (dateChange)="fromDateChange($event)" required>
                        <mat-datepicker-toggle matSuffix [for]="from_date">
                            <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
                        </mat-datepicker-toggle>
                        <mat-datepicker #from_date ></mat-datepicker>
                    </mat-form-field> -->
                
                    <mat-form-field class="form-date-p mt-1" appearance="fill">
                        <input matInput [matDatepicker]="to_date" [formControlName]="'to_date'" 
                        placeholder="{{mydate_to_date}}"
                        (dateChange)="toDateChange($event)" required>
                        <mat-datepicker-toggle matSuffix [for]="to_date">
                            <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
                        </mat-datepicker-toggle>
                        <mat-datepicker #to_date ></mat-datepicker>
                    </mat-form-field>
                    <button class="mt-1 timing-date filter-dashboard"
                        (click)="SendTimeWorkBranch()">
                        {{ 'Timing' | translate }}
                     </button>
                </form>
                <button class="set-null btn btn-danger" (click)="SetNull_SendTimeWorkBranch()">
                    <mat-icon [svgIcon]="'delete_forever'"></mat-icon>
                </button>
          </mat-step>
    </mat-vertical-stepper>
</div>