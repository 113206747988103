<div class="contar">
    <div class="header_areas">
      <div class="title_areas">{{ 'Edit Area Details Group' | translate }}</div>
      <button class="close-button"
              mat-icon-button
              matDialogClose>
      </button>
    </div>
  
          <ng-multiselect-dropdown
          class="multiselect"
          [settings]="dropdownSettings"
          [data]="dropdownList"
          [(ngModel)]="selectedItems"
          (onSelect)="onItemSelect($event)"
          (onSelectAll)="onSelectAll($event)"
          (onDeSelect)="onItemDeSelect($event)"
          (onDeSelectAll)="onDeSelectAll($event)"
          placeholder="{{ 'Select Areas' | translate }}" >
        </ng-multiselect-dropdown>
        
    <form [formGroup]="areasForm" class="areas_group_G">

        <mat-form-field>
            <mat-label class="label_areas_group">{{ 'Areas Group Name' | translate }}</mat-label>
            <input  placeholder="{{ 'type group name' | translate }}"  autofocus matInput class="line_height" [formControlName]="'areas_group_name'">
        </mat-form-field>
  
          <mat-form-field class="margin">
            <mat-label class="label_areas_group">{{ 'Work Hours First Period' | translate }}</mat-label>
            <input class="line_height" id="start_the_first_period"  type="time"  matInput [formControlName]="'start_the_first_period'">
          </mat-form-field>
  
        <mat-form-field class="margin">
            <input class="line_height" id="end_the_first_period"   type="time"  matInput [formControlName]="'end_the_first_period'">
        </mat-form-field>
  
        <mat-form-field class="margin">
            <mat-label class="label_areas_group">{{ 'Work Hours Second Period' | translate }}</mat-label>
            <input class="line_height" id="start_the_second_period" type="time"  matInput [formControlName]="'start_the_second_period'">
        </mat-form-field>
  
        <mat-form-field class="margin">
            <input class="line_height" id="end_the_second_period" type="time" matInput [formControlName]="'end_the_second_period'">
        </mat-form-field>
    
        <span *ngIf="message" style="margin-top: 20px;">
          <span *ngIf="message.type == 'success'" class="success">
            {{ message.content | translate }}
          </span>
           <span *ngIf="message.type == 'error'" class="error">
             {{ message.content | translate }}
          </span>
        </span>
  
        <div class="btn_">
            <button  type="button" class="btn btn-outline-primary" (click)="EditAreaGroup()">{{ 'Send' | translate }}</button>
        </div>
  
    </form>
    
  </div>