<form [formGroup]="checkoutForm" >
    <div dir="ltr">
      <div class="form__group">
        <input  class="form__input" id="name" type="password" [formControlName]="'password'"  placeholder="{{ 'change Passowrd' | translate }}" />
        <!-- <label for="name" class="form__label">{{ 'change Passowrd' | translate }}</label> -->
      </div>
    </div>
    <div *ngIf="message" class="message-chang-pass">
       {{ message.content | translate }}
    </div>
    <button class="btn btn-link"  (click)="onSubmit()">{{ 'click' | translate }} </button>
  </form>

