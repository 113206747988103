import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from './../../environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { Location, LocationStrategy } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class TranslationsService {

  _language : {
    locale    : string,
    native    : string,
    flag      : string | null,
    direction : 'rtl'  | 'ltr'
  }

  _userLanguage: string;

  private _languageSubject: BehaviorSubject<any>;

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _router: Router,
    private translate: TranslateService,
    private locationStrategy: LocationStrategy,
    private location: Location
  ) { 
    this._languageSubject = new BehaviorSubject(null);

    let defaultLanguage = environment.defaultLanguage;
    try {
      defaultLanguage = navigator.language.split('-')[0];
    }
    catch(error) {
      defaultLanguage = environment.defaultLanguage;
    }
    this.userLanguage = this.userLanguage || defaultLanguage;
    this.translate.use(this.userLanguage);
  }

  languageSubject() : Observable<any> {
    return this._languageSubject.asObservable();
  }

  set userLanguage(locale: string)
  {
    localStorage.setItem('user_language', locale);
    this.language = environment.supportedLanguages[ this.userLanguage ];
    this.translate.use(this.language.locale);
    this._languageSubject.next(this.language);
  }

  get userLanguage(): string
  {
    return localStorage.getItem('user_language');
  }

  set language (language) {
    this._language = language;
    if (this.translate.currentLang != language.locale) {
      this.translate.use(language.locale);
    }
  }

  get language () {
    return this._language
  }

  changeLanguage(language:  string) {
    let check = Object.keys(environment.supportedLanguages).some(s => s.includes(language || null));
    if (check) {
      this.userLanguage = language;
    }
  }

  setLanguage(_url: string) {
    let segments = _url == '/' ? [] : _url.split('/');
    let check    = Object.keys(environment.supportedLanguages).some(s => s.includes(segments[0] || null));
    if (! check) { 
      this._language = environment.supportedLanguages[ environment.defaultLanguage ];
      segments.unshift(environment.defaultLanguage);
    }
    else {
      this._language = environment.supportedLanguages[ segments[0] ];
      return false;
    }
    let url = this._router
    .createUrlTree(segments, {
      relativeTo: this._activatedRoute, 
      queryParams: {}
    })
    .toString();
    return url;
  }
}
