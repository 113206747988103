<div class="header">
    <div class="title">{{ 'Dear business partners' | translate }}</div>
    <button class="close-button"
            mat-icon-button
            (click)="closeModal()"
            [tabIndex]="-1">
        <mat-icon [svgIcon]="'close'"></mat-icon>
    </button>
</div>

<div class="modal-content">
    <p>
        {{ 'Due to the pandemic we are experiencing, we opened the wholesale shopping food section at discounted prices in order to support your business' | translate }}
    </p>
    <div class="actions">
        <div class="buttons" style="margin: auto;">
            <button class="send-button"
                mat-flat-button
                [color]="'primary'"
                (click)="openProductsList()">
                {{ 'View Products' | translate }}
            </button>
        </div>
    </div>
</div>


