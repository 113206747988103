import { UserService } from 'app/layout/common/user/user.service';
import { UserModel, BranchModel } from './../../layout/common/user/user.types';
import { environment } from './../../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { AuthUtils } from 'app/core/auth/auth.utils';

@Injectable()
export class AuthService
{
    // Private
    private _authenticated: boolean;

    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     */
    constructor(
        private _httpClient: HttpClient,
        private _userService: UserService,
    )
    {
        // Set the defaults
        this._authenticated = false;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Setter & getter for access token
     */
    set accessToken(token: string)
    {
        localStorage.setItem('access_token', token);
    }

    get accessToken(): string
    {
        return localStorage.getItem('access_token');
    }

    /**
     * Setter & getter for User
     */
    set User(user: UserModel)
    {
        localStorage.setItem('user', JSON.stringify(user));
        
        this._userService.user = user;
    }

    get User(): UserModel
    {
        return JSON.parse(localStorage.getItem('user'));
    }

    /**
     * Setter & getter for User
     */
    set Branch(branch: BranchModel)
    {
        localStorage.setItem('branch', JSON.stringify(branch));
    }

    get Branch(): BranchModel
    {
        return JSON.parse(localStorage.getItem('branch'));
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Sign in
     *
     * @param credentials
     */
    signIn(credentials: { phone_number: string, password: string, device_type: 'WEB' | 'ANDROID' | 'IOS' }): Observable<any>
    {
        // Throw error, if the user is already logged in
        if ( this._authenticated ) {
            return throwError('User is already logged in.');
        }

        return this._httpClient.post(environment.apiBaseUrl + '/auth/login_via_branch_app', credentials).pipe(
            switchMap((response: any) => {
                if (response['message'] == 'success') {                    
                    // Store the access token in the local storage
                    this.accessToken    = response.data.user.token;
                    // Store the user & branch in the local storage
                    this.User = response.data.user;
                    this.Branch = response.data.branch;
                    // console.log(response.data.branch);

                    // Set the authenticated flag to true
                    this._authenticated = true;
                }
                // Return a new observable with the response
                return of(response);
            })
        );
    }

    signInDierct(credentials: { phone_number: string, password: string, device_type: 'WEB' | 'ANDROID' | 'IOS' }): Observable<any>
    {
        // Throw error, if the user is already logged in
        if ( this._authenticated ) {
            return throwError('User is already logged in.');
        }
        
        return this._httpClient.post(environment.apiBaseUrl + '/auth/login_via_branch_app_dier', credentials).pipe(
            switchMap((response: any) => {
                if (response['message'] == 'success') {                    
                    // Store the access token in the local storage
                    this.accessToken    = response.data.user.token;
                    // Store the user & branch in the local storage
                    this.User = response.data.user;

                    this.Branch = response.data.branch;

                    // Set the authenticated flag to true
                    this._authenticated = true;
                }
                // Return a new observable with the response
                return of(response);
            })
        );
    }

    /**
     * Sign in using the access token
     */
    signInUsingToken(): Observable<any>
    {
        return of(true);

        // Renew token
        return this._httpClient.post('api/auth/refresh-access-token', {
            access_token: this.accessToken
        }).pipe(
            catchError(() => {

                // Return false
                return of(false);
            }),
            switchMap((response: any) => {

                // Store the access token in the local storage
                this.accessToken = response.access_token;

                // Set the authenticated flag to true
                this._authenticated = true;

                // Return true
                return of(true);
            })
        );
    }

    /**
     * Sign out
     */
    signOut(): Observable<any>
    {
        // Remove the access token from the local storage
        localStorage.removeItem('access_token');
        
        localStorage.removeItem('user');

        localStorage.removeItem('branch');

        // Set the authenticated flag to false
        this._authenticated = false;

        // Return the observable
        return of(true);
    }

    /**
     * Check the authentication status
     */
    check(): Observable<boolean>
    {
        // Check if the user is logged in
        if ( this._authenticated )
        {
            return of(true);
        }

        // Check the access token availability
        if ( !this.accessToken )
        {
            return of(false);
        }

        // Check the access token expire date
        if ( AuthUtils.isTokenExpired(this.accessToken) )
        {
            return of(false);
        }

        // If the access token exists and it didn't expire, sign in using it
        return this.signInUsingToken();
    }
}
