import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { Notification, NotificationModel } from 'app/layout/common/notifications/notifications.types';
import { map, switchMap, take } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class NotificationsService
{
    private _notifications: BehaviorSubject<Notification[] | null>;

    private _notificationsApi: BehaviorSubject<NotificationModel[] | null>;

    private _notificationsApiNew: BehaviorSubject<NotificationModel | null>;

    constructor(
        private _httpClient: HttpClient,
    )
    {
        this._notifications       = new BehaviorSubject(null);

        this._notificationsApi    = new BehaviorSubject(null);

        this._notificationsApiNew = new BehaviorSubject(null);
    }

    showNotification(data) {
        let notification = new Notification(data['title'], { 
            icon: data['icon'], 
            body: data['body'],
            dir :'auto'
        });
        setTimeout(function() {
            notification.close();
        }, 9000);
    }

    get notifications$(): Observable<Notification[]>
    {
        return this._notifications.asObservable();
    }

    get apiNotifications$(): Observable<NotificationModel[]>
    {
        return this._notificationsApi.asObservable();
    }

    get apiNewNotification$(): Observable<NotificationModel | null>
    {
        return this._notificationsApiNew.asObservable();
    }

    set apiNewNotification(value: NotificationModel)
    {
        this._notificationsApiNew.next(value);
    }

    storeFromApi(notifications: NotificationModel[]): Observable<NotificationModel[]>
    {
        this._notificationsApi.next(notifications);
        return this.apiNotifications$;
    }

    pushFromApi(newNotification: NotificationModel): NotificationModel
    {
        this._notificationsApiNew.next(newNotification);
        // this.apiNewNotification = newNotification;
        // this.apiNotifications$.pipe(
        //     take(1)
        // )
        // .subscribe((notifications) => {
        //     // Update the notifications with the new notification
        //     this._notificationsApi.next([...notifications, newNotification]);
        // });
        // Return the new notification
        return newNotification;
    }

    store(notifications: Notification[]): Observable<Notification[]>
    {
        this._notifications.next(notifications);
        return this.notifications$;
    }

    create(notification: Notification): Observable<Notification>
    {
        return this.notifications$.pipe(
            take(1),
            switchMap(notifications => this._httpClient.post<Notification>('api/common/notifications', {notification}).pipe(
                map((newNotification) => {

                    // Update the notifications with the new notification
                    this._notifications.next([...notifications, newNotification]);

                    // Return the new notification from observable
                    return newNotification;
                })
            ))
        );
    }

    update(id: string, notification: Notification): Observable<Notification>
    {
        return this.notifications$.pipe(
            take(1),
            switchMap(notifications => this._httpClient.patch<Notification>('api/common/notifications', {
                id,
                notification
            }).pipe(
                map((updatedNotification: Notification) => {

                    // Find the index of the updated notification
                    const index = notifications.findIndex(item => item.id === id);

                    // Update the notification
                    notifications[index] = updatedNotification;

                    // Update the notifications
                    this._notifications.next(notifications);

                    // Return the updated notification
                    return updatedNotification;
                })
            ))
        );
    }

    delete(id: string): Observable<boolean>
    {
        return this.notifications$.pipe(
            take(1),
            switchMap(notifications => this._httpClient.delete<boolean>('api/common/notifications', {params: {id}}).pipe(
                map((isDeleted: boolean) => {

                    // Find the index of the deleted notification
                    const index = notifications.findIndex(item => item.id === id);

                    // Delete the notification
                    notifications.splice(index, 1);

                    // Update the notifications
                    this._notifications.next(notifications);

                    // Return the deleted status
                    return isDeleted;
                })
            ))
        );
    }

    markAllAsRead(): Observable<boolean>
    {
        return this.notifications$.pipe(
            take(1),
            switchMap(notifications => this._httpClient.get<boolean>('api/common/notifications/mark-all-as-read').pipe(
                map((isUpdated: boolean) => {

                    // Go through all notifications and set them as read
                    notifications.forEach((notification, index) => {
                        notifications[index].read = true;
                    });

                    // Update the notifications
                    this._notifications.next(notifications);

                    // Return the updated status
                    return isUpdated;
                })
            ))
        );
    }
}
