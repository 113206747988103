import { AuthService } from 'app/core/auth/auth.service';
import { RoutesService } from './../../../../api/routes.service';
import { Component, HostBinding, Inject, OnDestroy, OnInit, Renderer2, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Data, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TreoMediaWatcherService } from '@treo/services/media-watcher';
import { TreoNavigationService } from '@treo/components/navigation';
import { DOCUMENT } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { ActionModalComponent } from './action-modal/action-modal.component';
import { CloseOpenBranch } from './close-open-branch/close-open-branch.component';
import { UserService } from 'app/layout/common/user/user.service';
import { BranchModel, UserModel } from 'app/layout/common/user/user.types';
import { TranslationsService } from 'app/helpers/translations.service';
import { NotifyRestaurantDATA } from '../material/material.types';

@Component({
    selector     : 'material-layout',
    templateUrl  : './material.component.html',
    styleUrls    : ['./material.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class MaterialLayoutComponent implements OnInit, OnDestroy
{    
    startIndex: number = 0;
    startIndexR: number = 0;
 
    data: any;

    branch: BranchModel;

    user: UserModel;

    NotifyRestaurantDATA : NotifyRestaurantDATA;

    isScreenSmall: boolean;

    currentStatus: '0' | '1' = '0';

    @HostBinding('class.fixed-header')
    fixedHeader: boolean;

    @HostBinding('class.fixed-footer')
    fixedFooter: boolean;

    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {ActivatedRoute} _activatedRoute
     * @param {Router} _router
     * @param {TreoMediaWatcherService} _treoMediaWatcherService
     * @param {TreoNavigationService} _treoNavigationService
     */
    constructor(
        private _translationsService: TranslationsService,
        private _userService: UserService,
        private _activatedRoute: ActivatedRoute,
        private _router: Router,
        private _treoMediaWatcherService: TreoMediaWatcherService,
        private _treoNavigationService: TreoNavigationService,
        private _routesService: RoutesService,
        private _authService: AuthService,
        private renderer: Renderer2,
        private _matDialog: MatDialog,
        @Inject(DOCUMENT) document, 
    )
    {        
        // Set the private defaults
        this._unsubscribeAll = new Subject();

        // Set the defaults
        this.fixedHeader = false;
        this.fixedFooter = false;

        renderer.setAttribute(document.body, 'current-user-name' , this._authService.Branch.title || '');
        renderer.setAttribute(document.body, 'current-user-phone', '(+90) ' + (this._authService.Branch.phone_number || ''));
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for current year
     */
    get currentYear(): number
    {
        return new Date().getFullYear();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {
            this.Repeat();
        // Subscribe to the resolved route data
        this._activatedRoute.data.subscribe((data: Data) => {
            this.data = data.initialData;
            this.currentStatus = this.data['branch']['online'];
            this.NotifyRestaurant();
        });
        // Subscribe to media changes
        this._treoMediaWatcherService.onMediaChange$
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe(({matchingAliases}) => {
            // Check if the breakpoint is 'lt-md'
            this.isScreenSmall = matchingAliases.includes('lt-md');
        });

        this._routesService.restaurantStatus({
            branch_id           : this.data.branch.id,
        })
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe((response: any) => {
            if (response.message == 'success') {
                this.currentStatus = response.data.result;
            }
        });

        // this._routesService.verifyAccount({
        //     branch_id           : this.data.branch.id,
        // })
        // .pipe(takeUntil(this._unsubscribeAll))
        // .subscribe((response: any) => {
        //     if (response.message == 'success') {
        //         // if(this._authService.User){
        //         //     if(response.data.result !=  "this account don't have chang" && response.data.result.password != this._authService.User.password){
        //         //         localStorage.removeItem('access_token');
                        
        //         //         localStorage.removeItem('user');
                        
        //         //         localStorage.removeItem('branch');
        //         //     }
        //         // }
        //     }

        //     // console.log(response.data.result);
            
        // });


    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    Repeat() {
        setTimeout(() => {
          this.FunctionSlide();
          this.Repeat();
        },5000);
      }

      FunctionSlide() {
        const slides = Array.from(document.getElementsByClassName('mall-show-slide'));
        for (const x of slides) {
          const y = x as HTMLElement;
          y.style.display = 'none';
        }
        if (this.startIndex > slides.length - 1) {
          this.startIndex = 0;
          const slide = slides[this.startIndex] as HTMLElement;
          if(this.startIndexR){
              slide.style.display = 'block';
              this.startIndex++;
            }
        } else {
          const slide = slides[this.startIndex] as HTMLElement;
          if(this.startIndexR){
                slide.style.display = 'block';
                this.startIndex++;
          }
        }
      }
    /**
     * Toggle navigation
     *
     * @param key
     */
    toggleNavigation(key): void
    {
        // Get the navigation
        const navigation = this._treoNavigationService.getComponent(key);

        if ( navigation )
        {
            // Toggle the opened status
            navigation.toggle();
        }
    }

    // switchStatus() : void
    // {
    //     this.currentStatus = this.currentStatus == '0' ? '1' : '0';

    //     this._routesService.updateBranchStatus({
    //         online   : this.currentStatus,
    //         branch_id: this.data.branch.id
    //     })
    //     .subscribe((res: any) => {
    //         if (res.message == 'success') {
    //             this.data.branch.online  = this.currentStatus;
    //             this._authService.Branch = this.data.branch;
    //         }
    //         else {
    //             this.currentStatus = this.currentStatus == '0' ? '1' : '0';
    //         }
    //     });
    // }

    NotifyRestaurant() : void
    {
        this._routesService.NotifyRestaurant({
            branch_id           : this.data.branch.id,
            locale              : this._translationsService._language.locale,
        })
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe((response: any) => {
            if (response.message == 'success') {                
                this.NotifyRestaurantDATA = response['data'].NotifyRestaurant;                
            if(response['data'].NotifyRestaurant.length >= 1){
                this.startIndexR = 1;
            }   
        }
    });
    }

    toggleStatusClose(){
        const dialogRef = this._matDialog.open(CloseOpenBranch);
        dialogRef.afterClosed().subscribe((result) => {
            this.currentStatus = result != undefined ? result : this.data.branch.online;
        })
    }
}
