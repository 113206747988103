import { DOCUMENT } from '@angular/common';
import { Component, ViewEncapsulation, OnInit, OnDestroy, AfterViewInit, Inject, Renderer2 } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Data, Router } from '@angular/router';
import { RoutesService } from 'app/api/routes.service';
import { AuthService } from 'app/core/auth/auth.service';
import { TranslationsService } from 'app/helpers/translations.service';
import { UserService } from 'app/layout/common/user/user.service';
import { BranchModel, UserModel } from 'app/layout/common/user/user.types';
import { TreoMediaWatcherService } from '@treo/services/media-watcher';
import { TreoNavigationService } from '@treo/components/navigation';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { formatDate } from "@angular/common";
import moment from 'moment';

const today = new Date();
const month = today.getMonth();
const year = today.getFullYear();

@Component({
	selector: 'close-open-branch',
	templateUrl: './close-open-branch.component.html',
	styleUrls: ['./close-open-branch.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class CloseOpenBranch implements OnInit {

    branch: BranchModel;

    data: any;

    isScreenSmall: boolean;

    user: UserModel;

    public timingForm: FormGroup;

    private _unsubscribeAll: Subject<any>;

    currentStatus: '0' | '1' = '0';

	// from_date         ?: string | Date = new Date();

    tody         ?: string | Date = new Date();

    to_date           ?: string | Date = new Date();

    // mydate_from_date  = formatDate(new Date(), 'YYYY/MM/dd', 'en-GB');
    mydate_to_date         : string;
    viewMyToDate           : boolean = false;
    viewMyEndDateTody      : boolean = false;
	constructor(
		public matDialogRef: MatDialogRef<CloseOpenBranch>,
        private _userService: UserService,
        private _formBuilder: FormBuilder,
        private _treoMediaWatcherService: TreoMediaWatcherService,
        private _routesService: RoutesService,
        private _authService: AuthService,
        @Inject(DOCUMENT) document, 
    ) 
    {   
        this._unsubscribeAll = new Subject();
	}

	ngOnInit(): void {

        this.timingForm = this._formBuilder.group({
            // from_date        : [ '', [Validators.nullValidator]],
            to_date          : [ '', [Validators.nullValidator]],
        });

		this._userService.user$
		.pipe(takeUntil(this._unsubscribeAll))
		.subscribe((user: UserModel) => {
			this.user = user;
		});
		this._userService.branch$
		.pipe(takeUntil(this._unsubscribeAll))
		.subscribe((branch: BranchModel) => {
			this.branch = branch;
			this.currentStatus = this.branch.online;
            this.mydate_to_date =  this.branch.work_branch_to_date;
		});
		// Subscribe to media changes
		this._treoMediaWatcherService.onMediaChange$
		.pipe(takeUntil(this._unsubscribeAll))
		.subscribe(({matchingAliases}) => {
			// Check if the breakpoint is 'lt-md'
			this.isScreenSmall = matchingAliases.includes('lt-md');
		});
 
        this._routesService.restaurantStatus({
            branch_id           : this.branch.id,
        })
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe((response: any) => {
            if (response.message == 'success') {
                this.currentStatus = response.data.result;
            }
        });


        if( formatDate(this.branch.work_branch_to_date, 'yyyy/MM/dd', 'en') > formatDate(new Date(), 'yyyy/MM/dd', 'en')){
            this.viewMyToDate  = true;
        }else{
            this.viewMyToDate  = false;
        }

	}

	// closeModal() {
	// 	this.matDialogRef.close(this.currentStatus);
	// }
    
    switchStatus() : void
    {		
        this.currentStatus = this.currentStatus == '0' ? '1' : '0';

        this._routesService.updateBranchStatus({
            online   : this.currentStatus,
            branch_id: this.branch.id
        })
        .subscribe((res: any) => {
            if (res.message == 'success') {
                this.branch.online  = this.currentStatus;
                this._authService.Branch = this.branch;
				this.matDialogRef.close(this.currentStatus);
            }
            else {
                this.currentStatus = this.currentStatus == '0' ? '1' : '0';
            }
        });
    }
    CloseToEndDay(){
        this.currentStatus = this.currentStatus == '0' ? '1' : '0';

        const currentDate = new Date();
        const timestamp = formatDate(currentDate, 'YYYY/MM/dd', 'en-GB');
        this._routesService.CloseToEndTody({
            branch_id: this.branch.id,
            CloseToEndTody : timestamp,
        })
        .subscribe((res: any) => {
            if (res.message == 'success') {
                this.branch.online  = this.currentStatus;
                this._authService.Branch = this.branch;
                this.matDialogRef.close(this.currentStatus);               
            }else{
                this.currentStatus = this.currentStatus == '0' ? '1' : '0';
            }
        });
    }

    // fromDateChange(event: any) {
    //     if (! event.value) {
    //         this.from_date = null;
    //     }
    //     else {
    //         let date = new Date(event.value);
    //         this.from_date = formatDate(date, 'YYYY/MM/dd', 'en-GB');
    //     }
    // }

    toDateChange(event: any) {
        if (! event.value) {
            this.to_date = null;
        }
        else {
            let date = new Date(event.value);
            this.to_date = formatDate(date, 'YYYY/MM/dd', 'en-GB');
        }
    }
    SendTimeWorkBranch(){
        this.currentStatus = this.currentStatus == '0' ? '1' : '0';

        this._routesService.editTheInsidersWorkingHours({
            branch_id: this.branch.id,
            // from_date:this.from_date,
            to_date: this.to_date
        })
        .subscribe((res: any) => {
            if (res.message == 'success') {
                this.branch.online  = this.currentStatus;
                this._authService.Branch = this.branch;
                this.mydate_to_date = res.data.result.to_date;
                var FromLocalStorage = JSON.parse(localStorage.getItem("branch"));
                FromLocalStorage.work_branch_to_date = res.data.result.to_date;
                localStorage.setItem("branch",JSON.stringify(FromLocalStorage));
				this.matDialogRef.close(this.currentStatus);                
            }else{
                this.currentStatus = this.currentStatus == '0' ? '1' : '0';
            }
        });
    }

    SetNull_CloseToEndDay(){
        this.currentStatus = this.currentStatus == '0' ? '1' : '0';
        this._routesService.CloseToEndTody({
            branch_id: this.branch.id,
            CloseToEndTody : null,
        })
        .subscribe((res: any) => {
            if (res.message == 'success') {
                this.branch.online  = this.currentStatus;
                this._authService.Branch = this.branch;
                this.matDialogRef.close(this.currentStatus);               
            }else{
                this.currentStatus = this.currentStatus == '0' ? '1' : '0';
            }
        });
    }

    SetNull_SendTimeWorkBranch(){
        this.currentStatus = this.currentStatus == '0' ? '1' : '0';
        this._routesService.editTheInsidersWorkingHours({
            branch_id: this.branch.id,
            to_date: null
        })
        .subscribe((res: any) => {
            if (res.message == 'success') {
                //  console.log(formatDate(new Date(), 'YYYY/MM/dd', 'en-GB'));
                 
                var FromLocalStorage = JSON.parse(localStorage.getItem("branch"));
                FromLocalStorage.work_branch_to_date = formatDate(new Date(), 'YYYY/MM/dd', 'en-GB');
                FromLocalStorage.online              = this.currentStatus;
                localStorage.setItem("branch",JSON.stringify(FromLocalStorage));

                // this.branch.online  = this.currentStatus;
                // this._authService.Branch = this.branch;
				this.matDialogRef.close(this.currentStatus);                
            }else{
                this.currentStatus = this.currentStatus == '0' ? '1' : '0';
            }
        });
    }

}
