import { Router } from '@angular/router';
import { UserModel } from 'app/layout/common/user/user.types';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { RoutesService } from './../../../api/routes.service';
import { AuthService } from 'app/core/auth/auth.service';
import { TranslationsService } from './../../../helpers/translations.service';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild, ViewContainerRef, ViewEncapsulation } from '@angular/core';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { MatButton } from '@angular/material/button';
import { Subject } from 'rxjs';
import { takeUntil, take } from 'rxjs/operators';
import { Notification, NotificationModel } from 'app/layout/common/notifications/notifications.types';
import { NotificationsService } from 'app/layout/common/notifications/notifications.service';
import { environment } from 'environments/environment';

@Component({
    selector       : 'notifications',
    templateUrl    : './notifications.component.html',
    styleUrls      : ['./notifications.component.scss'],
    encapsulation  : ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    exportAs       : 'notifications'
})
export class NotificationsComponent implements OnInit, OnDestroy
{
    unreadCount: number;
    private _notifications: Notification[];
    public _notificationsApi: NotificationModel[] | null;
    private _overlayRef: OverlayRef;
    private _unsubscribeAll: Subject<any>;

    @ViewChild('notificationsOrigin')
    private _notificationsOrigin: MatButton;

    @ViewChild('notificationsPanel')
    private _notificationsPanel: TemplateRef<any>;

    constructor(
        private _router: Router,
        private _changeDetectorRef: ChangeDetectorRef,
        private _notificationsService: NotificationsService,
        private _overlay: Overlay,
        private _viewContainerRef: ViewContainerRef,
        private __ : TranslationsService,
        private _AuthService: AuthService,
        private _routesServices: RoutesService,
        private angularFireMessaging: AngularFireMessaging,
    )
    {
        this._unsubscribeAll = new Subject();
        this.unreadCount = 0;
    }

    @Input()
    set notifications(value: Notification[])
    {
        this._notificationsService.store(value);
    }

    get notifications(): Notification[]
    {
        return this._notifications;
    }

    get apiNotifications(): NotificationModel[]
    {
        return this._notificationsApi;
    }

    ngOnInit(): void
    {
        // Subscribe to notification changes
        // this._notificationsService.notifications$
        // .pipe(takeUntil(this._unsubscribeAll))
        // .subscribe((notifications: Notification[]) => {
        //     // Load the notifications
        //     this._notifications = notifications;
        //     // Calculate the unread count
        //     this._calculateUnreadCount();
        //     // Mark for check
        //     this._changeDetectorRef.markForCheck();
        // });

        // Subscribe to notification changes
        this._notificationsService.apiNotifications$
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe((notifications: NotificationModel[]) => {
            // Load the notifications
            this._notificationsApi = notifications;
            // Calculate the unread count
            this._calculateUnreadCount();
            // Mark for check
            this._changeDetectorRef.markForCheck();
        });

        this.requestPermission(this._AuthService.User, this.__.language.locale);
    }

    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
        // Dispose the overlay if it's still on the DOM
        if ( this._overlayRef ) {
            this._overlayRef.dispose();
        }
    }

    loadNotificationsFromApi(withLoader: boolean = true) 
    {
        if (withLoader) {
            this._notificationsApi = null;
        }
        this._routesServices.getNotifications({
            page   : 1,
            type   : 'RESTAURANT_ADMINS',
            locale : this.__.language.locale
        })
        .pipe(take(1))
        .subscribe((res) => {
            if (res.message == 'success') {
                this._notificationsService.storeFromApi(res.data.notifications);
            }
            // console.log(res);
        });
    }

    private _calculateUnreadCount(): void
    {
        let count = 0;
        if ( this.notifications && this.notifications.length ) {
            count = this.notifications.filter((notification) => notification.read === false).length;
        }
        this.unreadCount = count;
    }

    createRouteFromLink(link): string[]
    {
        const route = link.split('/');
        route.unshift('/');
        return route;
    }

    openPanel(): void
    {
        this._overlayRef = this._overlay.create({
            hasBackdrop     : true,
            backdropClass   : '',
            scrollStrategy  : this._overlay.scrollStrategies.block(),
            positionStrategy: this._overlay.position()
            .flexibleConnectedTo(this._notificationsOrigin._elementRef.nativeElement)
            .withFlexibleDimensions()
            .withViewportMargin(16)
            .withLockedPosition()
            .withPositions([
                {
                    originX : 'start',
                    originY : 'bottom',
                    overlayX: 'start',
                    overlayY: 'top'
                },
                {
                    originX : 'start',
                    originY : 'top',
                    overlayX: 'start',
                    overlayY: 'bottom'
                },
                {
                    originX : 'end',
                    originY : 'bottom',
                    overlayX: 'end',
                    overlayY: 'top'
                },
                {
                    originX : 'end',
                    originY : 'top',
                    overlayX: 'end',
                    overlayY: 'bottom'
                }
            ])
        });
        // Create a portal from the template
        const templatePortal = new TemplatePortal(this._notificationsPanel, this._viewContainerRef);
        // Attach the portal to the overlay
        this._overlayRef.attach(templatePortal);
        // Subscribe to the backdrop click
        this._overlayRef.backdropClick().subscribe(() => {
            // If overlay exists and attached...
            if ( this._overlayRef && this._overlayRef.hasAttached() ) {
                // Detach it
                this._overlayRef.detach();
            }
            // If template portal exists and attached...
            if ( templatePortal && templatePortal.isAttached ) {
                // Detach it
                templatePortal.detach();
            }
        });
        this.loadNotificationsFromApi();
    }

    markAllAsRead(): void
    {
        this._notificationsService.markAllAsRead().subscribe();
    }

    toggleRead(notification): void
    {
        notification.read = !notification.read;
        this._notificationsService.update(notification.id, notification).subscribe();
    }

    delete(notification): void
    {
        this._notificationsService.delete(notification.id).subscribe();
    }

    requestPermission(_user: UserModel, _locale = 'ar') : void 
    {
        this.angularFireMessaging.requestToken
        .pipe(take(1))
        .subscribe((token) => {
            if (token) {
                this._routesServices.sendFirebaseToken({
                    token       : token,
                    device_type : 'IOS', //Just to recive notification key
                    device_id   : _user.id ? _user.id + '_WEB_DEVICE' : '123',
                    locale      : _locale
                })
                .pipe(take(1))
                .subscribe((res) => {
                    this.receiveMessages();
                });
            }
        });
    }

    receiveMessages() : void 
    {
        let self = this;
        // this.angularFireMessaging.onBackgroundMessage((msg) => {
        //   console.log("onBackgroundMessage!", msg);
        // });
        this.angularFireMessaging.messages
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe((msg) => {
            // console.log('Foreground', msg);
            this.loadNotificationsFromApi(false);
            this._notificationsService.pushFromApi(msg['data']);
            if (! msg['from_background']) {
                this._notificationsService.showNotification({
                    title : msg['data']['title'],
                    body  : msg['data']['body'],
                    icon  : msg['data']['icon'],
                });
                try { 
                    self.playSound(0);
                } catch (error) {}
            }
        });

        navigator.serviceWorker.addEventListener('message', function(event) {
            // console.log(event.data['firebaseMessaging'] || event.data);
            console.log('Service Worker To Foreground', event.data);
            self.loadNotificationsFromApi(false);
            self._notificationsService.pushFromApi(event.data);
            self.playSound(0);
        });
    }

    openNotification(notification: NotificationModel) : void 
    {
        if (notification.entity_type == 'Order') {
            this._router.navigate(['/', 'orders', notification.entity_id]);
        }
    }

    playSound(i: number = 0): void 
    {
        i = i + 1;
        let self = this;
        let audios = [
            environment.newOrderFile,
            environment.newOrderFile,
            environment.newOrderFile,
            environment.newOrderFile,
        ];
        if (! audios[ i ]) {
            return;
        }
        let audio  = new Audio(audios[ i ]);
        audio.play();
        audio.onended = function() {
            self.playSound(i);
        };
    }
}
