import { environment } from './../../../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { tap, map, catchError, take } from 'rxjs/operators';
import { User, UserModel, BranchModel } from 'app/layout/common/user/user.types';

@Injectable({
    providedIn: 'root'
})
export class UserService
{
    // Observables
    private _user  : BehaviorSubject<UserModel | null>;

    private _branch: BehaviorSubject<BranchModel | null>;

    private _mapLoaded: BehaviorSubject<boolean>;

    private is_map_loaded: boolean;

    constructor(
        private _httpClient: HttpClient
    )
    {
        // Set the defaults
        this._user      = new BehaviorSubject(null);

        this._branch    = new BehaviorSubject(null);

        this._mapLoaded = new BehaviorSubject(false);

        this.is_map_loaded = false;
    }

    set user(value: UserModel)
    {
        // Store the value
        this._user.next(value);
    }

    get user$(): Observable<UserModel>
    {
        return this._user.asObservable();
    }

    set branch(value: BranchModel)
    {
        this._branch.next(value);
    }

    get branch$(): Observable<UserModel>
    {
        return this._branch.asObservable();
    }

    set mapLoaded(value: boolean)
    {
        // Store the value
        this.is_map_loaded = value;
        this._mapLoaded.next(value);
    }

    get mapLoaded$(): Observable<boolean>
    {
        return this._mapLoaded.asObservable();
    }

    update(user: UserModel): Observable<any>
    {
        return this._httpClient.patch('api/common/user', {user}).pipe(
            tap(() => {
                // Execute the observable
                this._user.next(user);
            })
        );
    }

    updateBranch(branch: BranchModel)
    {
        this._branch.next(branch);
        // return this._httpClient.patch('api/common/user', {branch}).pipe(
        //     tap(() => {
        //         // Execute the observable
        //         this._branch.next(branch);
        //     })
        // );
    }

    loadMapApi(): any
    {
        if (this.is_map_loaded) {
            return false;
        }

        this._httpClient.jsonp('https://maps.googleapis.com/maps/api/js?key=' + environment.GOOGLE_MAP_API_KEY, 'callback')
        .pipe(
          map(() => this.mapLoaded = true)
        );
    }
}
