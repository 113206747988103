<!-- Button -->
<button class="icon-button" mat-icon-button [matMenuTriggerFor]="userActions">
    <span class="avatar">
        <img *ngIf="showAvatar && user.image" [src]="branch.logo">
        <mat-icon *ngIf="!showAvatar || !user.image" [svgIcon]="'account_circle'"></mat-icon>
        <span class="status" [ngClass]="{'online': user.online == '1', 'away' : user.online == '0'}"></span>
    </span>
</button>

<span *ngIf="_branch">{{_branch.title}}</span>

<mat-menu class="user-actions-menu" [xPosition]="'before'" #userActions="matMenu">
    <button mat-menu-item>
        <span class="user-info">
            <span>{{ 'Signed in as' | translate }}</span>
            
            <span class="email">{{user.email}}</span>
        </span>
    </button>
    <mat-divider class="my-2"></mat-divider>
    <!-- <button mat-menu-item>
        <mat-icon [svgIcon]="'account_circle'"></mat-icon>
        <span>{{ 'Profile' | translate }}</span>
    </button> -->
    <button mat-menu-item (click)="goToSettings()">
        <mat-icon [svgIcon]="'settings'"></mat-icon>
        <span>{{ 'Settings' | translate }}</span>
    </button>

    <button mat-menu-item (click)="openDialog()">
        <mat-icon [svgIcon]="'touch_app'"></mat-icon>
        <span>{{ 'change Passowrd' | translate }}</span>
    </button>

    <button mat-menu-item [matMenuTriggerFor]="userLanguage">
        <mat-icon [svgIcon]="'flag'"></mat-icon>
        <span>{{ 'Language' | translate }}</span>
    </button>
    <!-- <button mat-menu-item [matMenuTriggerFor]="userStatus">
        <mat-icon [svgIcon]="'radio_button_unchecked'"></mat-icon>
        <span>{{ 'Status' | translate }}</span>
    </button> -->
    <mat-divider class="my-2"></mat-divider>
    <button mat-menu-item (click)="signOut()">
        <mat-icon [svgIcon]="'power_settings_new'"></mat-icon>
        <span>{{ 'Sign out' | translate }}</span>
    </button>
</mat-menu>

<mat-menu class="user-status-menu" #userLanguage="matMenu">
    <button mat-menu-item *ngFor="let language of supportedLanguages" (click)="changeLanguage(language.locale)">
        <mat-icon [svgIcon]="'radio_button_unchecked'"></mat-icon>
        <span>{{ language.native }}</span>
    </button>
</mat-menu>

<!-- <mat-menu class="user-status-menu" #userStatus="matMenu">
    <button mat-menu-item (click)="updateUserStatus('1')">
        <span class="status online"></span>
        <span>Online</span>
    </button>
    <button mat-menu-item (click)="updateUserStatus('0')">
        <span class="status away"></span>
        <span>Away</span>
    </button>
    <button mat-menu-item (click)="updateUserStatus('busy')">
        <span class="status busy"></span>
        <span>Busy</span>
    </button>
    <button mat-menu-item (click)="updateUserStatus('not-visible')">
        <span class="status not-visible"></span>
        <span>Invisible</span>
    </button>
</mat-menu> -->