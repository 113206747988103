// export const environment = {

//     production: true,

//     apiBaseUrl  : 'https://tr.hungry-app.com/api',

//     newOrderFile  : 'https://kitchen.joaan.app/assets/sounds/new_orders_notification.mpeg',

//     //Dont forgot to copy the areas.php file to root directory of the project
//     areasApiUrl : 'http://localhost/jo3aan-kitchen/areas.php',

//     emptyImage: 'https://tr.hungry-app.com/graph/uploads/150x150/dishes_images/default.png',

//     appName: 'Joaan Kitchen',

//     defaultLanguage: 'en',

//     supportedLanguages: {
//         ar: {
//             locale    : 'ar',
//             native    : 'Arabic',
//             flag      : null,
//             direction : 'rtl'
//         },
//         en: {
//             locale    : 'en',
//             native    : 'English',
//             flag      : null,
//             direction : 'ltr'
//         },
//         tr: {
//             locale    : 'tr',
//             native    : 'Turkish',
//             flag      : null,
//             direction : 'ltr'
//         }
//     },

//     GOOGLE_MAP_API_KEY: 'AIzaSyDavhzRPyB3w8Ua2RLB9Xn_ZV8Gl5PdJfU',

//     firebase : {
//         apiKey: "AIzaSyDdiDkfnZ16DWdZFPUNkyz6Ut5lN6wGEh0",
//         authDomain: "jo3aan-c3733.firebaseapp.com",
//         databaseURL: "https://jo3aan-c3733.firebaseio.com",
//         projectId: "jo3aan-c3733",
//         storageBucket: "jo3aan-c3733.appspot.com",
//         messagingSenderId: "388971019517",
//         appId: "1:388971019517:web:db6ff87785b68e69ca5294",
//         measurementId: "G-TG2HXXQ77V"
//     },
// };

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    
    production: true,

    apiBaseUrl    : 'https://tr.hungry-app.com/api',
    // apiBaseUrl    : 'http://127.0.0.1:8000/api',
    //    apiBaseUrl    : 'https://api.joaan-app.com/api',
    
    newOrderFile  : 'https://kitchen.joaan.app/assets/sounds/new_orders_notification.mpeg',

    //Dont forgot to copy the areas.php file to root directory of the project
    areasApiUrl : 'http://localhost/jo3aan-kitchen/areas.php',

    emptyImage: 'https://tr.hungry-app.com/graph/uploads/150x150/dishes_images/default.png',

    appName: 'Joaan Kitchen',

    defaultLanguage: 'en',

    supportedLanguages: {
        ar: {
            locale    : 'ar',
            native    : 'Arabic',
            flag      : null,
            direction : 'rtl'
        },
        en: {
            locale    : 'en',
            native    : 'English',
            flag      : null,
            direction : 'ltr'
        },
        tr: {
            locale    : 'tr',
            native    : 'Turkish',
            flag      : null,
            direction : 'ltr'
        }
    },

    GOOGLE_MAP_API_KEY: 'AIzaSyDavhzRPyB3w8Ua2RLB9Xn_ZV8Gl5PdJfU',

    firebase : {
        apiKey: "AIzaSyDdiDkfnZ16DWdZFPUNkyz6Ut5lN6wGEh0",
        authDomain: "jo3aan-c3733.firebaseapp.com",
        databaseURL: "https://jo3aan-c3733.firebaseio.com",
        projectId: "jo3aan-c3733",
        storageBucket: "jo3aan-c3733.appspot.com",
        messagingSenderId: "388971019517",
        appId: "1:388971019517:web:db6ff87785b68e69ca5294",
        measurementId: "G-TG2HXXQ77V"
    },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
