import { Observable } from 'rxjs';
import { Meal } from 'app/modules/admin/meals/meals.types';
import { environment } from './../../environments/environment';
import { Router, UrlSerializer } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslationsService } from 'app/helpers/translations.service';

@Injectable({
  providedIn: 'root'
})
export class RoutesService {

  constructor(private http: HttpClient, private router: Router, private serializer: UrlSerializer, private _translationsService: TranslationsService) { }

  private buildUrlString(data?: any) {
    data           = ! data      ? {} : data;
    data.page      = ! data.page ? 1  : data.page;

    let params = this.router.createUrlTree([''], { queryParams: data });
    return this.serializer.serialize(params).substring(1);
  }

  postRequest(data?: any) : Observable<any> {
    return this.http.post( environment.apiBaseUrl + '/auth/enter_phone_number', data || {}, { 
      headers: {'locale': this._translationsService.language.locale} 
    });
  }

  getRequest(data?: any) : Observable<any> {
    return this.http.get( environment.apiBaseUrl + '/addresses' + this.buildUrlString(data), { 
      headers: {'locale': this._translationsService.language.locale} 
    });
  }

  

  getMeals(data?: any) : Observable<any> {
    return this.http.get( environment.apiBaseUrl + '/dishes' + this.buildUrlString(data), { 
      headers: {'locale': this._translationsService.language.locale} 
    });
  }
  
  CreateMeals(data?: any) : Observable<any> {
    return this.http.get( environment.apiBaseUrl + '/dishes/create' + this.buildUrlString(data), { 
      headers: {'locale': this._translationsService.language.locale} 
    });
  }
  indexSideDish(data?: any) : Observable<any> {
    return this.http.get( environment.apiBaseUrl + '/dishes/indexSideDish' + this.buildUrlString(data), { 
      headers: {'locale': this._translationsService.language.locale} 
    });
  }
  BringRestaurantOpeningHours(data?: any) : Observable<any> {
    return this.http.get( environment.apiBaseUrl + '/restaurants/BringRestaurantOpeningHours' + this.buildUrlString(data), { 
      headers: {'locale': this._translationsService.language.locale} 
    });
  }
  StoreMeals(data?: any) : Observable<any> {
    return this.http.post( environment.apiBaseUrl + '/dishes/storeDish' , data || {}, { 
      headers: {'locale': this._translationsService.language.locale} 
    });
  }
  ChangePrice(data?: any) : Observable<any> {
    return this.http.post( environment.apiBaseUrl + '/dishes/changePrice' , data || {}, { 
      headers: {'locale': this._translationsService.language.locale} 
    });
  }
  StoreSideDish(data?: any) : Observable<any> {
    return this.http.post( environment.apiBaseUrl + '/dishes/storeSideDish' , data || {}, { 
      headers: {'locale': this._translationsService.language.locale} 
    });
  }
  editTheInsidersWorkingHours(data?: any) : Observable<any> {
    return this.http.post( environment.apiBaseUrl + '/restaurants/editTheInsidersWorkingHours' , data || {}, { 
      headers: {'locale': this._translationsService.language.locale} 
    });
  }
  CloseToEndTody(data?: any) : Observable<any> {
    return this.http.post( environment.apiBaseUrl + '/restaurants/CloseToEndTody' , data || {}, { 
      headers: {'locale': this._translationsService.language.locale} 
    });
  }
  UpdateRestaurantOpeningHours(data?: any) : Observable<any> {
    return this.http.post( environment.apiBaseUrl + '/restaurants/UpdateRestaurantOpeningHours' , data || {}, { 
      headers: {'locale': this._translationsService.language.locale} 
    });
  }
  CloseToEndTodyCategory(data?: any) : Observable<any> {
    return this.http.post( environment.apiBaseUrl + '/categories/CloseToEndTody' , data || {}, { 
      headers: {'locale': this._translationsService.language.locale} 
    });
  }
  getcategory(data?: any) : Observable<any> {
    return this.http.get( environment.apiBaseUrl + '/categories' + this.buildUrlString(data), { 
      headers: {'locale': this._translationsService.language.locale} 
    });
  }

  updateCategory(data?: any) : Observable<any> {
    return this.http.post( environment.apiBaseUrl + '/categories/updateCategory' , data || {}, { 
      headers: {'locale': this._translationsService.language.locale} 
    });
  }
  resetCategory(data?: any) : Observable<any> {
    return this.http.post( environment.apiBaseUrl + '/categories/resetCategory' , data || {}, { 
      headers: {'locale': this._translationsService.language.locale} 
    });
  }

  getAreas(data?: any) : Observable<any> {
    return this.http.get( environment.apiBaseUrl + '/areas' + this.buildUrlString(data), { 
      headers: {'locale': this._translationsService.language.locale} 
    });
  }

  AddAreasGroup(data?: any) : Observable<any> {
    return this.http.post( environment.apiBaseUrl + '/areas/AddAreasGroup' , data || {}, { 
      headers: {'locale': this._translationsService.language.locale} 
    });
  }
  removeAreas(Data) : Observable<any> {
    return this.http.post( environment.apiBaseUrl + '/areas/removeAreas', Data, { 
      headers: {'locale': this._translationsService.language.locale} 
    });
  }
  updateAreasGroup(data?: any) : Observable<any> {
    return this.http.get( environment.apiBaseUrl + '/areas/updateAreasGroup' + this.buildUrlString(data), { 
      headers: {'locale': this._translationsService.language.locale} 
    });
  }
  saveAreasGroup(Data) : Observable<any> {
    return this.http.post( environment.apiBaseUrl + '/areas/saveAreasGroup', Data, { 
      headers: {'locale': this._translationsService.language.locale} 
    });
  }
  
  removeAreasGroup(Data) : Observable<any> {
    return this.http.post( environment.apiBaseUrl + '/areas/removeAreasGroup', Data, { 
      headers: {'locale': this._translationsService.language.locale} 
    });
  }

  updateMealDetails(data?: any) : Observable<any> {
    return this.http.post( environment.apiBaseUrl + '/dishes/update_details' , data || {}, { 
      headers: {'locale': this._translationsService.language.locale} 
    });
  }

  restaurantStatus(data?: any) : Observable<any> {
    return this.http.get( environment.apiBaseUrl + '/restaurants/restaurant_status' + this.buildUrlString(data), { 
      headers: {'locale': this._translationsService.language.locale} 
    });
  }
  updateMealStatus(data?: any) : Observable<any> {
    return this.http.post( environment.apiBaseUrl + '/restaurants/toggle_availability' , data || {}, { 
      headers: {'locale': this._translationsService.language.locale} 
    });
  }

  getFinance(data?: any) : Observable<any> {
    return this.http.get( environment.apiBaseUrl + '/orders/orders-data' + this.buildUrlString(data), { 
      headers: {'locale': this._translationsService.language.locale} 
    });
  }

  getOrders(data?: any) : Observable<any> {
    return this.http.get( environment.apiBaseUrl + '/orders' + this.buildUrlString(data), { 
      headers: {'locale': this._translationsService.language.locale} 
    });
  }

  getOrdersCount(data?: any) : Observable<any> {
    return this.http.get( environment.apiBaseUrl + '/orders/order_count_by_status_group' + this.buildUrlString(data), { 
      headers: {'locale': this._translationsService.language.locale} 
    });
  }

  NotifyRestaurant(data?: any) : Observable<any> {
    return this.http.get( environment.apiBaseUrl + '/notify_restaurant' + this.buildUrlString(data), { 
      headers: {'locale': this._translationsService.language.locale} 
    });
  }

  GetBranchReviews(data?: any) : Observable<any> {
    return this.http.get( environment.apiBaseUrl + '/restaurants/get_branch_reviews' + this.buildUrlString(data), { 
      headers: {'locale': this._translationsService.language.locale} 
    });
  }
  ReplyToReview(data?: any): Observable<any> {
    return this.http.post(environment.apiBaseUrl + '/restaurants/reply_to_review', data || {}, {
      headers: { 'locale': this._translationsService.language.locale }
    });
  }

  changPassword(data?: any) : Observable<any> {
    return this.http.post( environment.apiBaseUrl + '/auth/changPassword'  , data || {}, { 
      headers: {'locale': this._translationsService.language.locale} 
    });
  }
  verifyAccount(data?: any) : Observable<any> {
    return this.http.get( environment.apiBaseUrl + '/auth/verify_account' + this.buildUrlString(data), { 
      headers: {'locale': this._translationsService.language.locale} 
    });
  }
  acceptOrRejectOrder(data?: any) : Observable<any> {
    return this.http.post( environment.apiBaseUrl + '/orders/accept_order' , data || {}, { 
      headers: {'locale': this._translationsService.language.locale} 
    });
  }

  assignDriverToOrder(data?: any) : Observable<any> {
    return this.http.post( environment.apiBaseUrl + '/orders/assign_driver' , data || {}, { 
      headers: {'locale': this._translationsService.language.locale} 
    });
  }

  markOrderReady(data?: any) : Observable<any> {
    return this.http.post( environment.apiBaseUrl + '/orders/mark_order_dishes_ready' , data || {}, { 
      headers: {'locale': this._translationsService.language.locale} 
    });
  }

  markOrderDelivered(data?: any) : Observable<any> {
    return this.http.post( environment.apiBaseUrl + '/orders/mark_order_delivered' , data || {}, { 
      headers: {'locale': this._translationsService.language.locale} 
    });
  }

  updateBranchDetails(data?: any) : Observable<any> {
    return this.http.post( environment.apiBaseUrl + '/restaurants/update_settings' , data || {}, { 
      headers: {'locale': this._translationsService.language.locale} 
    });
  }

  updateBranchStatus(data?: any) : Observable<any> {
    return this.http.post( environment.apiBaseUrl + '/restaurants/update_status' , data || {}, { 
      headers: {'locale': this._translationsService.language.locale} 
    });
  }

  updateBranchCoveringArea(data?: any) : Observable<any> {
    return this.http.post( environment.apiBaseUrl + '/restaurants/update_covered_areas' , data || {}, { 
      headers: {'locale': this._translationsService.language.locale} 
    });
  }



  getDrivers(data?: any) : Observable<any> {
    return this.http.get( environment.apiBaseUrl + '/restaurants/drivers' + this.buildUrlString(data), { 
      headers: {'locale': this._translationsService.language.locale} 
    });
  }

  getCampaigns(data?: any) : Observable<any> {
    return this.http.get( environment.apiBaseUrl + '/campaigns' + this.buildUrlString(data), { 
      headers: {'locale': this._translationsService.language.locale} 
    });
  }
  
  goSubscribe(data?: any) : Observable<any> {
    return this.http.post( environment.apiBaseUrl + '/campaigns/subscribe' , data || {}, { 
      headers: {'locale': this._translationsService.language.locale} 
    });
  }

  goUnsubscribe(data?: any) : Observable<any> {
    return this.http.post( environment.apiBaseUrl + '/campaigns/unsubscribe'  , data || {}, { 
      headers: {'locale': this._translationsService.language.locale} 
    });
  }

  createDriver(data?: any) : Observable<any> {
    return this.http.post( environment.apiBaseUrl + '/restaurants/link_driver' , data || {}, { 
      headers: {'locale': this._translationsService.language.locale} 
    });
  }

  updateDriverDetails(data?: any) : Observable<any> {
    return this.http.post( environment.apiBaseUrl + '/restaurants/update_driver' , data || {}, { 
      headers: {'locale': this._translationsService.language.locale} 
    });
  }

  deleteDriver(data?: any) : Observable<any> {
    return this.http.post( environment.apiBaseUrl + '/restaurants/unlink_driver' , data || {}, { 
      headers: {'locale': this._translationsService.language.locale} 
    });
  }

  requestJoaanDelivery(data?: any) : Observable<any> {
    return this.http.post( environment.apiBaseUrl + '/v2/orders/CreateRest' , data || {}, { 
      headers: {'locale': this._translationsService.language.locale} 
    });
  }

  updateDriverStatus(data?: any) : Observable<any> {
    return this.http.post( environment.apiBaseUrl + '/restaurants/update_driver_status' , data || {}, { 
      headers: {'locale': this._translationsService.language.locale} 
    });
  }

  getNotifications(data?: any) : Observable<any> {
    return this.http.get( environment.apiBaseUrl + '/notifications' + this.buildUrlString(data), { 
      headers: {'locale': this._translationsService.language.locale} 
    });
  }

  sendFirebaseToken(Data) {
    return this.http.post( environment.apiBaseUrl + '/tokens/set_token', Data, { 
      headers: {'locale': this._translationsService.language.locale} 
    });
  }

  getCoveredAreas(data?: any) : Observable<any> {
    return this.http.get( environment.apiBaseUrl + '/restaurants/get_covered_areas' + this.buildUrlString(data), { 
      headers: {'locale': this._translationsService.language.locale} 
    });
  }

  getAreaPolygon(data?: any) : Observable<any> {
    return this.http.get(environment.apiBaseUrl + '/restaurants/get_area_information' +  this.buildUrlString(data), { 
      headers: {'locale': this._translationsService.language.locale} 
    });
  }

  removeSupportedArea(Data) : Observable<any> {
    return this.http.post( environment.apiBaseUrl + '/restaurants/delete_covered_area', Data, { 
      headers: {'locale': this._translationsService.language.locale} 
    });
  }

  addNewSupportedArea(Data) : Observable<any> {
    return this.http.post( environment.apiBaseUrl + '/restaurants/create_covered_area', Data, { 
      headers: {'locale': this._translationsService.language.locale} 
    });
  }

  updateSupportedArea(Data) : Observable<any> {
    return this.http.post( environment.apiBaseUrl + '/restaurants/update_covered_area', Data, { 
      headers: {'locale': this._translationsService.language.locale} 
    });
  }

  loadAreaSelector(data?: any) : Observable<any> {
    return this.http.get( environment.apiBaseUrl + '/restaurants/get_all_neighborhoods_by_city' + this.buildUrlString(data), { 
      headers: {'locale': this._translationsService.language.locale} 
    });
  }

  // branch_id=INT&since=Y/m/d&until=Y/m/d
  loadStatistics(data?: any) : Observable<any> {
    return this.http.get( environment.apiBaseUrl + '/restaurants/get_branch_with_statistics' + this.buildUrlString(data), { 
      headers: {'locale': this._translationsService.language.locale} 
    });
  }

  loadDishesCategories(data?: any) : Observable<any> {
    return this.http.get( environment.apiBaseUrl + '/dishes/categories' + this.buildUrlString(data), { 
      headers: {'locale': this._translationsService.language.locale} 
    });
  }
  
  loadFinance(data?: any) : Observable<any> {
    return this.http.get( environment.apiBaseUrl + '/restaurants/finacial_statistics' + this.buildUrlString(data), { 
      headers: {'locale': this._translationsService.language.locale} 
    });
  }

  loadBranchIndex(data?: any) : Observable<any> {
    return this.http.get( environment.apiBaseUrl + '/restaurants/branch_index' + this.buildUrlString(data), { 
      headers: {'locale': this._translationsService.language.locale} 
    });
  }
}
