import { environment } from './../environments/environment';
import { Route } from '@angular/router';
import { AuthGuard } from 'app/core/auth/guards/auth.guard';
import { NoAuthGuard } from 'app/core/auth/guards/noAuth.guard';
import { LayoutComponent } from 'app/layout/layout.component';
import { InitialDataResolver } from 'app/app.resolvers';
import { RedirectToLinkComponent } from './redirect-to-link/redirect-to-link.component';

let appName         = environment.appName;

// @formatter:off
// tslint:disable:max-line-length
export const appRoutes: Route[] = [

    // Redirect empty path to '/example'
    {path: '', pathMatch : 'full', redirectTo: 'dashboard'},

    // Redirect signed in user to the '/example'
    {path: 'signed-in-redirect', pathMatch : 'full', redirectTo: 'dashboard'},

    // Auth routes (guest)
    {
        path: '',
        canActivate: [NoAuthGuard],
        canActivateChild: [NoAuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty',
            title : appName
        },
        children: [
            {path: 'confirmation-required', loadChildren: () => import('app/modules/auth/confirmation-required/confirmation-required.module').then(m => m.AuthConfirmationRequiredModule)},
            {path: 'forgot-password'      , loadChildren: () => import('app/modules/auth/forgot-password/forgot-password.module').then(m => m.AuthForgotPasswordModule)},
            {path: 'reset-password'       , loadChildren: () => import('app/modules/auth/reset-password/reset-password.module').then(m => m.AuthResetPasswordModule)},
            {path: 'sign-in'              , loadChildren: () => import('app/modules/auth/sign-in/sign-in.module').then(m => m.AuthSignInModule), data: {title: appName + ' | Sign In'}},
            {path: 'sign-up'              , loadChildren: () => import('app/modules/auth/sign-up/sign-up.module').then(m => m.AuthSignUpModule)}
        ]
    },

    // Auth routes (logged in)
    {
        path: '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            {path: 'sign-out'      , loadChildren: () => import('app/modules/auth/sign-out/sign-out.module').then(m => m.AuthSignOutModule), data: {title: appName + ' | Sign Out'}},
            {path: 'unlock-session', loadChildren: () => import('app/modules/auth/unlock-session/unlock-session.module').then(m => m.AuthUnlockSessionModule)}
        ]
    },

    // Landing routes
    {
        path: '',
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            {path: 'home', loadChildren: () => import('app/modules/landing/home/home.module').then(m => m.LandingHomeModule)},
        ]
    },

    // Admin routes
    {
        path: '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        resolve: {
            initialData: InitialDataResolver,
        },
        children: [
            {path: 'dashboard'            , loadChildren: () => import('app/modules/admin/dashboard/dashboard.module').then(m => m.DashboardModule), data: {title: appName + ' | Dashboard'}},
            {path: 'menu'                 , loadChildren: () => import('app/modules/admin/meals/meals.module').then(m => m.MealsModule), data: {title: appName + ' | Menu'}},
            {path: 'categories'           , loadChildren: () => import('app/modules/admin/category/meals.module').then(m => m.MealsModule), data: {title: appName + ' | categories'}},
            {path: 'drivers'              , loadChildren: () => import('app/modules/admin/drivers/drivers.module').then(m => m.DriversModule), data: {title: appName + ' | Drivers'}},
            {path: 'campaigns'            , loadChildren: () => import('app/modules/admin/campaign/campaigns.module').then(m => m.CampaignsModule), data: {title: appName + ' | Campaigns'}},
            {path: 'orders'               , loadChildren: () => import('app/modules/admin/orders/orders.module').then(m => m.OrdersModule), data: {title: appName + ' | Orders'}},
            {path: 'finance'              , loadChildren: () => import('app/modules/admin/finance/orders.module').then(m => m.OrdersModule), data: {title: appName + ' | finance'}},
            {path: 'covering-areas'       , loadChildren: () => import('app/modules/admin/areas/areas.module').then(m => m.AreasModule), data: {title: appName + ' | Covering Areas'}},
            {path: 'settings'             , loadChildren: () => import('app/modules/admin/settings/settings.module').then(m => m.SettingsModule), data: {title: appName + ' | Settings'}},
            {path: 'joaan-delivery'       , loadChildren: () => import('app/modules/admin/joaan-delivery/joaan-delivery.module').then(m => m.JoaanDeliveryModule), data: {title: appName + ' | Delivery'}},
            {path: 'Comments-and-ratings' , loadChildren: () => import('app/modules/admin/CommentsAndRatings/Comments-and-ratings.module').then(m => m.Comments), data: {title: appName + ' | Comments And Ratings'}},
            {path: 'redirect'             , component   : RedirectToLinkComponent}
            // 404 & Catch all
            // {path: '404-not-found', pathMatch: 'full', loadChildren: () => import('app/modules/admin/pages/errors/error-404/error-404.module').then(m => m.Error404Module)},
            // {path: '**', redirectTo: '404-not-found'}

            // Apps
            // {
            //     path: 'apps', 
            //     children: [
            //         {path: 'calendar', loadChildren: () => import('app/modules/admin/apps/calendar/calendar.module').then(m => m.CalendarModule)},
            //         {path: 'contacts', loadChildren: () => import('app/modules/admin/apps/contacts/contacts.module').then(m => m.ContactsModule), data: {title: appName + ' | Menu'}},
            //         {path: 'ecommerce', loadChildren: () => import('app/modules/admin/apps/ecommerce/ecommerce.module').then(m => m.ECommerceModule)},
            //         {path: 'mailbox', loadChildren: () => import('app/modules/admin/apps/mailbox/mailbox.module').then(m => m.MailboxModule)},
            //         {path: 'tasks', loadChildren: () => import('app/modules/admin/apps/tasks/tasks.module').then(m => m.TasksModule)},
            //     ]
            // },
        ]
    },

    
];
