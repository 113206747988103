<!-- Notifications toggle -->
<button class="notifications-toggle" mat-icon-button (click)="openPanel()" #notificationsOrigin>
    <mat-icon *ngIf="unreadCount > 0" [svgIcon]="'notifications_active'"></mat-icon>
    <mat-icon *ngIf="unreadCount === 0" [svgIcon]="'notifications'"></mat-icon>
</button>

<!-- Notifications panel -->
<ng-template #notificationsPanel>

    <div class="notifications-panel">

        <div class="header">

            <div class="title">
                {{ 'Notifications' | translate }}
            </div>

            <!-- <button class="mark-all-as-read" mat-icon-button [matTooltip]="'Mark all as read'" (click)="markAllAsRead()">
                <mat-icon [svgIcon]="'playlist_add_check'"></mat-icon>
            </button> -->

        </div>

        <div class="content">

            <ng-container *ngIf="apiNotifications">

                <ng-container *ngFor="let notification of apiNotifications">

                    <div class="notification unread">
                        <a href="javascript:;" (click)="openNotification(notification)">
                            <ng-container *ngTemplateOutlet="notificationContent"></ng-container>
                        </a>
                        <div class="actions">
                            <button class="indicator" mat-icon-button>
                                <span class="unread"></span>
                            </button>
                        </div>
                    </div>
                    <ng-template #notificationContent>
                        <img class="image" src="assets/images/logo/notification.png">
                        <div class="notification-content">
                            <div class="title" *ngIf="notification.title" [innerHTML]="notification.title"></div>
                            <div class="description" *ngIf="notification.body" [innerHTML]="notification.body"></div>
                            <div class="time">{{notification.created_at | date:'MMM dd, h:mm a'}}</div>
                        </div>
                    </ng-template>
                </ng-container>
            </ng-container>

            <!-- <ng-container *ngFor="let notification of notifications">

                <div class="notification" [ngClass]="{'unread': !notification.read}">

                    <ng-container *ngIf="notification.link">
                        <a *ngIf="!notification.useRouter"
                           [href]="notification.link">
                            <ng-container *ngTemplateOutlet="notificationContent"></ng-container>
                        </a>

                        <a *ngIf="notification.useRouter"
                           [routerLink]="createRouteFromLink(notification.link)">
                            <ng-container *ngTemplateOutlet="notificationContent"></ng-container>
                        </a>
                    </ng-container>

                    <span *ngIf="!notification.link">
                        <ng-container *ngTemplateOutlet="notificationContent"></ng-container>
                    </span>

                    <div class="actions">

                        <button class="indicator" mat-icon-button (click)="toggleRead(notification)" [matTooltip]="notification.read ? 'Mark as unread' : 'Mark as read'">
                            <span *ngIf="!notification.read" class="unread"></span>
                            <span *ngIf="notification.read" class="read"></span>
                        </button>

                        <button class="remove" mat-icon-button (click)="delete(notification)" [matTooltip]="'Remove'">
                            <mat-icon [svgIcon]="'close'"></mat-icon>
                        </button>

                    </div>

                </div>

                <ng-template #notificationContent>

                    <mat-icon class="icon" *ngIf="notification.icon && !notification.image" [svgIcon]="notification.icon">
                    </mat-icon>

                    <img class="image" *ngIf="notification.image" [src]="notification.image">

                    <div class="notification-content">
                        <div class="title" *ngIf="notification.title" [innerHTML]="notification.title"></div>
                        <div class="description" *ngIf="notification.description" [innerHTML]="notification.description"></div>
                        <div class="time">{{notification.time | date:'MMM dd, h:mm a'}}</div>
                    </div>

                </ng-template>

            </ng-container> -->

            <ng-container *ngIf="!apiNotifications">
                <div class="empty">
                    <mat-progress-spinner mode="indeterminate" style="margin: auto;"></mat-progress-spinner>
                </div>
            </ng-container>
            <ng-container *ngIf="apiNotifications && apiNotifications.length == 0">
                <div class="empty">
                    <mat-icon [svgIcon]="'notifications'"></mat-icon>
                    <span>No notifications</span>
                </div>
            </ng-container>

        </div>

    </div>

</ng-template>
