import { Component, ViewEncapsulation, OnInit, OnDestroy, AfterViewInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthService } from 'app/core/auth/auth.service';

@Component({
	selector: 'app-action-modal',
	templateUrl: './action-modal.component.html',
	styleUrls: ['./action-modal.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class ActionModalComponent implements OnInit {

	constructor(
		public matDialogRef: MatDialogRef<ActionModalComponent>,
		private _auth: AuthService
	) { }

	ngOnInit(): void {
	}

	closeModal() {
		this.matDialogRef.close();
	}

	openProductsList() {
		window.open('https://tr.hungry-app.com/api/wholesale/index?token=' + this._auth.accessToken);
		this.matDialogRef.close();
	}
}
