/* tslint:disable:max-line-length */
import { TreoNavigationItem } from '@treo/components/navigation';

export const defaultNavigation: TreoNavigationItem[] = [
    {
        id      : 'dashboard',
        title   : 'Dashboard',
        type    : 'basic',
        icon    : 'home',
        link    : '/dashboard'
    },
    {
        id      : 'menu',
        title   : 'Menu',
        type    : 'basic',
        icon    : 'restaurant',
        link    : '/menu'
    },
    {
        id      : 'drivers',
        title   : 'Drivers',
        type    : 'basic',
        icon    : 'people',
        link    : '/drivers'
    },
    {
        id      : 'orders',
        title   : 'Orders',
        type    : 'basic',
        icon    : 'receipt',
        link    : '/orders'
    },
    // {
    //     id      : 'starter',
    //     title   : 'Starter',
    //     subtitle: 'Treo Starter Kit',
    //     type    : 'group',
    //     icon    : 'apps',
    //     children: [
    //         {
    //             id   : 'starter.example',
    //             title: 'Example component',
    //             type : 'basic',
    //             link : '/example'
    //         },
    //         {
    //             id   : 'starter.dummy.1',
    //             title: 'Dummy menu item #1',
    //             type : 'basic'
    //         },
    //         {
    //             id   : 'starter.dummy.2',
    //             title: 'Dummy menu item #1',
    //             type : 'basic'
    //         }
    //     ]
    // }
];
export const compactNavigation: TreoNavigationItem[] = [
    {
        id      : 'starter',
        title   : 'Starter',
        type    : 'aside',
        icon    : 'apps',
        children: [] // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
    }
];
export const futuristicNavigation: TreoNavigationItem[] = [
    {
        id   : 'starter.example',
        title: 'Example component',
        type : 'basic',
        icon : 'heroicons:chart-pie',
        link : '/example'
    },
    {
        id   : 'starter.dummy.1',
        title: 'Dummy menu item #1',
        icon : 'heroicons:calendar',
        type : 'basic'
    },
    {
        id   : 'starter.dummy.2',
        title: 'Dummy menu item #1',
        icon : 'heroicons:user-group',
        type : 'basic'
    }
];
export const horizontalNavigation: TreoNavigationItem[] = [
    {
        id      : 'dashboard',
        title   : 'Dashboard',
        type    : 'basic',
        icon    : 'home',
        link    : '/dashboard'
    },
    {
        id      : 'menu',
        title   : 'Menu',
        type    : 'basic',
        icon    : 'restaurant',
        link    : '/menu'
    },
    {
        id      : 'drivers',
        title   : 'Drivers',
        type    : 'basic',
        icon    : 'people',
        link    : '/drivers'
    },
    {
        id      : 'orders',
        title   : 'Orders',
        type    : 'basic',
        icon    : 'receipt',
        link    : '/orders'
    },
    // {
    //     id      : 'starter',
    //     title   : 'Starter',
    //     type    : 'group',
    //     icon    : 'apps',
    //     children: [] // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
    // }
];
