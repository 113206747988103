import { environment } from './../../../../environments/environment';
import { TranslationsService } from './../../../helpers/translations.service';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { User, UserModel, BranchModel } from 'app/layout/common/user/user.types';
import { UserService } from 'app/layout/common/user/user.service';
import { MatDialog } from '@angular/material/dialog';
import { GreetingsComponent } from 'app/greetings/greetings.component';

@Component({
    selector       : 'user',
    templateUrl    : './user.component.html',
    styleUrls      : ['./user.component.scss'],
    encapsulation  : ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    exportAs       : 'user'
})
export class UserComponent implements OnInit, OnDestroy
{
    supportedLanguages : any[];

    @Input() showAvatar: boolean;

    // Private
    private _unsubscribeAll: Subject<any>;
    private _user: UserModel;
    
    public _branch: BranchModel;

    /**
     * Constructor
     *
     * @param {ChangeDetectorRef} _changeDetectorRef
     * @param {Router} _router
     * @param {UserService} _userService
     */
    constructor(
        private matDialog: MatDialog,
        private _changeDetectorRef: ChangeDetectorRef,
        private _router: Router,
        private _userService: UserService,
        private _translationsService: TranslationsService
    )
    {
        // Set the private defaults
        this._unsubscribeAll = new Subject();

        // Set the defaults
        this.showAvatar = true;

        this.supportedLanguages = Object.values(environment.supportedLanguages);
    }

    openDialog()
    {
        this.matDialog.open(GreetingsComponent)
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Setter & getter for user
     *
     * @param value
     */
    @Input()
    set user(value: UserModel)
    {
        // Save the user
        this._user = value;

        // Store the user in the service
        this._userService.user = value;
    }

    get user(): UserModel
    {
        return this._user;
    }

    @Input()
    set branch(value: BranchModel)
    {
        // Save the user
        this._branch = value;

        // Store the user in the service
        this._userService.branch = value;
        // console.log(this._branch);
    }

    get branch(): BranchModel
    {
        return this._branch;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {
        // Subscribe to user changes
        this._userService.user$
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe((user: UserModel) => {
            this._user = user;
        });

        // Subscribe to user changes
        this._userService.branch$
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe((branch: BranchModel) => {
            this._branch = branch;
        });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Update the user status
     *
     * @param status
     */
    updateUserStatus(status): void
    {
        // Update the user data
        // this.user.status = status;

        // Update the user on the server
        this._userService.update(this.user);
        this._userService.updateBranch(this.branch);
    }

    /**
     * Sign out
     */
    signOut(): void
    {
        this._router.navigate(['/', 'sign-out']);
    }


    changeLanguage(language: string): void {
        this._translationsService.changeLanguage(language);
        window.location.reload();
    }

    goToSettings(): void {
        this._router.navigate(['/', 'settings']);
    }
}
