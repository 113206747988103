<!-- Navigation -->
<treo-vertical-navigation class="bg-cool-gray-900 theme-dark"
    *ngIf="isScreenSmall"
    [appearance]="'classic'"
    [mode]="'over'"
    [name]="'mainNavigation'"
    [navigation]="data.navigation.default"
    [opened]="false">

    <div treoVerticalNavigationContentHeader>
        <div class="logo">
            <img src="assets/images/logo/logo-text-on-dark.svg">
        </div>
    </div>

</treo-vertical-navigation>

<!-- Wrapper -->
<div class="wrapper">

    <div class="header-img"></div>
    <!-- Header -->
    <div class="header">

        <!-- Header container -->
        <div class="container">

            <!-- Top bar -->
            <div class="top-bar">

                <user [user]="data.user" [branch]="data.branch"></user>

                <!-- Navigation toggle button -->
                <button class="navigation-toggle-button"
                    *ngIf="isScreenSmall"
                    mat-icon-button
                    (click)="toggleNavigation('mainNavigation')">
                    <mat-icon [svgIcon]="'menu'"></mat-icon>
                </button>

                <!-- Spacer -->
                <div class="spacer"></div>

                <button class="navigation-toggle-button-close-open"
                    mat-icon-button
                    (click)="toggleStatusClose()">
                    <strong class="d-inline" *ngIf="currentStatus == '1'"><span class="iconify iconify-open" data-icon="mdi:lightbulb-on"></span>{{ 'Restaurant Available' | translate }}</strong>
                    <strong class="d-inline" *ngIf="currentStatus == '0'"><span class="iconify iconify-close" data-icon="mdi:lightbulb-off-outline"></span>{{ 'Restaurant Closed' | translate }}</strong>
                </button>


                <!-- <mat-slide-toggle (change)="switchStatus()" [checked]="currentStatus == '1'" [color]="'primary'" style="margin-left: 15px; margin-right: 15px;">
                    <strong *ngIf="currentStatus == '1'" class="text-success">{{ 'Available' | translate }}</strong>
                    <strong *ngIf="currentStatus == '0'" class="text-danger">{{ 'Closed' | translate }}</strong>
                </mat-slide-toggle> -->

                
                <!-- Search -->
                <!-- <search [appearance]="'bar'"></search> -->

                <!-- Shortcuts -->
                <!-- <shortcuts [shortcuts]="data.shortcuts"></shortcuts> -->

                <!-- Messages -->
                <!-- <messages [messages]="data.messages"></messages> -->

                <!-- Notifications -->
                <notifications [notifications]="data.notifications"></notifications>

                <!-- User -->
                

                  <!-- Logo -->
                  <!-- <div class="logo"
                    *ngIf="!isScreenSmall">
                    <img class="logo-text"
                        src="assets/images/logo/logo-text.svg">
                    <img class="logo-text-on-dark"
                        src="assets/images/logo/logo-text-on-dark.svg">
                   </div>  -->

            </div>

            <!-- Bottom bar -->
            <div class="bottom-bar" *ngIf="!isScreenSmall">
                <treo-horizontal-navigation [name]="'mainNavigation'" [navigation]="data.navigation.horizontal"></treo-horizontal-navigation>
            </div>

        </div>

    </div>

    <!-- Content -->
    <div class="content">
        <div class="mall-slide" *ngIf="startIndexR === 1">
            <div class="slid-content">
                <div class="mall-show-slide" *ngFor="let item of NotifyRestaurantDATA;">
                    <div class="notyfy" [ngStyle]="{backgroundColor: '#' + item.background_color, color: '#' + item.text_color }">
                        <span >
                            {{ item.notify_restaurant_name }}
                            <a href="{{item.link_notify_restaurant}}" target="_black" class="mx-2" *ngIf="item.link_notify_restaurant">
                                {{ 'click' | translate }}
                            </a>
                        </span>
                    </div>
                </div>
            </div>
        </div>

        <!-- *ngIf="true" hack is required here for router-outlet to work correctly. Otherwise,
        it won't register the changes on the layout and won't update the view. -->
        <router-outlet *ngIf="true"></router-outlet>
    </div>
</div>
