import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { UserService } from 'app/layout/common/user/user.service';
import { RoutesService } from '../api/routes.service';
import { fromEvent, Observable, Subject } from 'rxjs';
import {  takeUntil , take} from 'rxjs/operators';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AreaItemTimeDealog } from '../modules/admin/areas/areas.types';
import { UserModel, BranchModel } from 'app/layout/common/user/user.types';
import { TranslationsService } from '../helpers/translations.service';
import { Component, OnInit, AfterViewInit, Inject, ChangeDetectorRef, ElementRef, Input } from '@angular/core';
import { remove } from 'lodash';
import { IDropdownSettings } from 'ng-multiselect-dropdown';

@Component({
  selector: 'app-areasTime',
  templateUrl: './areasTime.component.html',
  styleUrls: ['./areasTime.component.scss']
})

export class AreasTimeComponent implements OnInit ,AfterViewInit{


  dropdownList = [];

  selectedItems = [];

  dropdownSettings = {};

  selectedItemsSelected : any[];

  selectedItemsL : {};

  selectedItemsS : {};

  initialData : { user ?: UserModel, branch ?: BranchModel } = {};

  parentData  : any;

  areasForm   : FormGroup;

  message     : any = null;

  areasGroupData      : AreaItemTimeDealog[];

  covered_areas       :AreaItemTimeDealog [];

  areasGroupDat       : AreaItemTimeDealog[];

  areasGroupDatLength : [];

  select_data    : any[];

  select_loading : boolean;

  search         : string;

  selected_area  : number;

  page           : number = 1;

  municipality_id   : string;
  
  has_free_delivery : string;

  private _unsubscribeAll: Subject<any>;

  constructor(
      private _userService: UserService,
      public matDialogRef: MatDialogRef<AreasTimeComponent>,
      private _formBuilder: FormBuilder,
      private _translationsService: TranslationsService,
      private _routesService: RoutesService,
      private _changeDetectorRef: ChangeDetectorRef,
      @Inject(MAT_DIALOG_DATA) public data: {user: UserModel, branch: BranchModel, data: any, signalFromModal: any},
  )
  {
      this._unsubscribeAll = new Subject();
      this.select_data = [];
  }
    ngAfterViewInit(): void {
        //
    }

  ngOnInit(): void
  {
    this.dropdownSettings = {
        singleSelection: false,
        idField: 'item_id',
        textField: 'item_text',
        selectAllText: 'Select All',
        unSelectAllText: 'UnSelect All',
        itemsShowLimit: 3,
        allowSearchFilter: true
    };
    
    this.areasForm = this._formBuilder.group({
        areas_group_name         : ['' , [Validators.required]],
        start_the_first_period   : ['' , [Validators.required]],
        end_the_first_period     : ['' , [Validators.required]],
        start_the_second_period  : ['' , [Validators.required]],
        end_the_second_period    : ['' , [Validators.required]],
    });

      this._userService.user$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((user: UserModel) => {
          this.initialData.user = user;
      });

      this._userService.branch$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((branch: BranchModel) => {
          this.initialData.branch = branch;
      });
      this.areasForm.setValue({
          areas_group_name         : '',
          start_the_first_period   : '',
          end_the_first_period     : '',
          start_the_second_period  : '',
          end_the_second_period    : '',
      });
      this.areasForm.enable();
      this.loadData();
  }

  onItemSelect(item: any[]){
      let ex = [];
      ex.push(item);
     this.selectedItemsSelected = ex;
  }
  onSelectAll(items: any[]) {
    this.selectedItemsSelected = items;
  }

  ngOnDestroy(): void
  {
      this._unsubscribeAll.next();
      this._unsubscribeAll.complete();
  }

  onScrollToEnd() {
      this.page++;
  }

  loadData(): void 
  {
    this.message = null;
    this._routesService.getCoveredAreas({
          branch_id : this.initialData.branch.id,
    })
    .pipe(takeUntil(this._unsubscribeAll))
    .subscribe((res) => {
          if (res.message == 'success') {
              this.covered_areas = res.data.covered_areas;
              this.parseAreaTimes(res.data.covered_areas);              
          }   else {
            this.message = {
                appearance: 'outline',
                content   : res.errors.global,
                shake     : false,
                showIcon  : false,
                type      : 'error'
            };
            this.areasForm.enable();
        }
    });
    
    this._routesService.getAreas({
        branch_id: this.initialData.branch.id,
    })
    .pipe(takeUntil(this._unsubscribeAll))
    .subscribe((res: any) => {
        if (res.message == 'success') {
            this.areasGroupDatLength =   res.data.time_areas.length;
            this.parseAreaTime(res.data.time_areas);
        }   else {
            this.message = {
                appearance: 'outline',
                content   : res.errors.global,
                shake     : false,
                showIcon  : false,
                type      : 'error'
            };
            this.areasForm.enable();
        }
    });    
  }
  parseAreaTime(AreaItemTime: AreaItemTimeDealog[]) : void
  {
      const item = [];
      AreaItemTime.forEach(element => {
              item.push({
                id                        : element.areas_G_id,
                branch_id                 : element.branch_id,
                end_the_first_period      : element.end_the_first_period,
                end_the_second_period     : element.end_the_second_period,
                group_areas_name          : element.group_areas_name,
                start_the_first_period    : element.start_the_first_period,
                start_the_second_period   : element.start_the_second_period,
              });
              
              this.areasGroupDat = item;
      });
  }

  parseAreaTimes(AreaItemTime: AreaItemTimeDealog[]) : void
  {
    const data = [];
    AreaItemTime.forEach((element) => {
        data.push({
            item_id   :  element.neighborhood_id,
            item_text :  element.neighborhood_name,
        })    
    })
    this.dropdownList = data;
  }

  addAreaGroup() {      
    this.areasForm.disable();
    this.message = null;
    
    const exist = [];
    this.selectedItemsSelected.forEach((el): void => {
        this.covered_areas.find((existing) => {
            if(existing.neighborhood_id === el.item_id){
                exist.push(existing.id)
            }            
        });
    });
    
    let areaData = this.areasForm.value;   
    let formData = new FormData();
    this._routesService.AddAreasGroup({
        branch                  : this.initialData.branch.id,
        areas_group_name        : areaData.areas_group_name,
        start_the_first_period  : areaData.start_the_first_period,
        end_the_first_period    : areaData.end_the_first_period,
        start_the_second_period : areaData.start_the_second_period,
        end_the_second_period   : areaData.end_the_second_period,
        dataSelect              : this.selectedItemsSelected,
        covered_areas           : exist,
    })
    .pipe(take(1)).subscribe((res: any) => {
        this.areasForm.enable();
        if (res.message == 'success') {
            this.areasForm.setValue({
                areas_group_name         : String(areaData.areas_group_name),
                start_the_first_period   : String(areaData.start_the_first_period),
                end_the_first_period     : String(areaData.end_the_first_period),
                start_the_second_period  : String(areaData.start_the_second_period),
                end_the_second_period    : String(areaData.end_the_second_period),
            });
            this.message = {
                appearance: 'outline',
                content   : res.message,
                shake     : true,
                showIcon  : false,
                type      : 'success'
            };            
        }
        else if (res.errors.global.length > 0) {                
            this.message = {
                appearance: 'outline',
                content   : res.errors.global,
                shake     : true,
                showIcon  : false,
                type      : 'error'
            };
        }
    });
}
}
