import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserService } from 'app/layout/common/user/user.service';
import { RoutesService } from './../api/routes.service';
import { fromEvent, Observable, Subject } from 'rxjs';
import { filter, switchMap, takeUntil } from 'rxjs/operators';
import { BranchModel, UserModel } from 'app/layout/common/user/user.types';

@Component({
  selector: 'app-greetings',
  templateUrl: './greetings.component.html',
  styleUrls: ['./greetings.component.scss']
})
export class GreetingsComponent implements OnInit {
  branch: BranchModel;
  phone_number: UserModel;
  user: UserModel;
  private _unsubscribeAll: Subject<any>;
  message: any;

  constructor(
    private _formBuilder: FormBuilder,
     private formBuilder: FormBuilder,
     private _userService: UserService,
     private _routesService: RoutesService,
     ) 
    {  
       // Set the private defaults
      this._unsubscribeAll = new Subject();
    }
    checkoutForm = this.formBuilder.group({
      password  :  ['' , [Validators.nullValidator]],
    });
  
  ngOnInit(): void
  {
    this._userService.branch$
    .pipe(takeUntil(this._unsubscribeAll))
    .subscribe((branch: BranchModel) => {
        this.branch = branch;
    });
    this._userService.user$
    .pipe(takeUntil(this._unsubscribeAll))
    .subscribe((user: UserModel) => {
        this.user = user;
    });
  }


  onSubmit(): void {
    let password = this.checkoutForm.value;
    let formData = new FormData();
    formData.append('id', String(this.user.id));
    formData.append('branch_id', String(this.branch.id));
    formData.append('_token',  String(localStorage.getItem('access_token')));
    formData.append('phone_number', String(this.user.phone_number));
    formData.append('password', password.password);

    this._routesService.changPassword(formData).pipe(takeUntil(this._unsubscribeAll))
  .subscribe((response: any) => {
    if (response.message == 'success') {
      this.message = {
          appearance: 'outline',
          content   : response.message,
          shake     : true,
          showIcon  : false,
          type      : 'success'
      };
      // this.closeDrawer();
  }
  });
}
}
