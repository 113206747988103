import { TranslationsService } from './helpers/translations.service';
import { environment } from './../environments/environment';
import { DOCUMENT, Location } from '@angular/common';
import { Component, Inject, Renderer2, OnDestroy } from '@angular/core';

import { Title } from '@angular/platform-browser';
import { Router, NavigationEnd, ActivatedRoute, NavigationError, NavigationStart } from '@angular/router';
import { filter, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';


@Component({
    selector   : 'app-root',
    templateUrl: './app.component.html',
    styleUrls  : ['./app.component.scss']
})
export class AppComponent implements OnDestroy
{
    private _document        : any;

    private _currentLanguage : any = null;

    private _unsubscribeAll  : Subject<any>;

    /**
     * Constructor
     */
    constructor (
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private titleService: Title,
        @Inject(DOCUMENT) document, 
        private renderer: Renderer2,
        private translationHelperService: TranslationsService,
        private location: Location,
    )
    {
        this._unsubscribeAll  = new Subject();
        this._document        = document;
        this._currentLanguage = translationHelperService.language;
        renderer.addClass(this._document.body, 'app-direction-' + translationHelperService.language.direction);
        if (translationHelperService.language.direction != 'ltr') {
            renderer.setAttribute(this._document.body, 'dir', translationHelperService.language.direction);
        }
        renderer.setAttribute(this._document.body, 'locale', translationHelperService.language.locale);
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    ngOnInit() { 

        this.router.events.pipe(
            filter(event => event instanceof NavigationStart),
        )
        .subscribe(() => {

        })
        
        this.router.events.pipe(
            filter(event => event instanceof NavigationEnd),
        )
        .subscribe(() => {
            // let check = this.translationHelperService.setLanguage( this.router.url );
            // console.log('End: '+ this.router.url)
            // if (check) {
            //     this.location.go( check );
            // }
            var rt = this.getChild(this.activatedRoute)
            rt.data.subscribe(data => {
                this.titleService.setTitle(data.title)
            })
        })

        this.router.events.pipe(
            filter(event => event instanceof NavigationError),
        )
        .subscribe((error) => {
            // let check = this.translationHelperService.setLanguage( this.router.url );
            // console.log('Error: '+ this.router.url)
            // if (check) {
            //     this.location.go( check );
            // }
        })

        this.translationHelperService
        .languageSubject()
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe((Language) => {
            this.renderer.removeClass(this._document.body, 'app-direction-' + this._currentLanguage.direction);
            this.renderer.addClass(document.body, 'app-direction-' + this.translationHelperService.language.direction);
            this.renderer.removeAttribute(this._document.body, 'dir');
            if (this.translationHelperService.language.direction != 'ltr') {
                this.renderer.setAttribute(document.body, 'dir', this.translationHelperService.language.direction);
            }
            this._currentLanguage = Language;
        });
    }
     
    getChild(activatedRoute: ActivatedRoute) { 
        if (activatedRoute.firstChild) {
            return this.getChild(activatedRoute.firstChild);
        } 
        else {
            return activatedRoute;
        }
    }
}
